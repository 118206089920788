import React from 'react';
import { motion } from "framer-motion";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TicketListCard from './TicketListCard';
import PlateCard from './PlateCard';
import logo from './hitchvibe_logo.png';

export default function TicketCard(props) {
    const { ticket, hideSensitive } = props; // Assuming these props are passed

    return (
        // <motion.div
        //     initial={{ opacity: 0, y: 50 }}
        //     animate={{ opacity: 1, y: 0 }}
        //     transition={{ duration: 0.5 }}
        // >
        <div className='w-full flex justify-around'>
            <Card className="w-full max-w-sm border rounded-lg overflow-hidden" key={props.ticket._id} style={{ borderRadius: '0.7em' }} raised>
                <CardContent style={{
                    padding: "1em"
                }}>
                    <div className='py-3 flex justify-between'>
                        <div>
                            <img src={logo} alt="HitchVibe" style={{height: 'auto', width: '9rem'}} />
                        </div>
                        <Typography variant="p" className="text-gray-300 text-right" style={{ fontSize: "0.8em" }} fontWeight={1000}>TICKET</Typography>
                    </div>
                    <div className="flex flex-col space-y-1">
                        <div className="flex justify-between items-center text-center">
                            <Typography variant="p" fontWeight={500} style={{ fontSize: "0.7em" }}>Destination</Typography>
                            <Typography variant="h6" fontWeight={700} className="text-center">{ticket.destination}</Typography>
                        </div>
                        <div className="flex justify-between items-center mt-4 text-center">
                            <Typography variant="p" fontWeight={500} style={{ fontSize: "0.7em" }}>Date</Typography>
                            <Typography variant="h6" fontWeight={700} className="text-center">{ticket.date}</Typography>
                        </div>
                        <div className="flex justify-between items-center mt-4 text-center">
                            <Typography variant="p" fontWeight={500} style={{ fontSize: "0.7em" }}>Time</Typography>
                            <Typography variant="h6" fontWeight={700} className="text-center">{ticket.time}</Typography>
                        </div>
                        <div className="mt-8 border-t border-2 border-gray-200 dark:border-gray-600"></div>
                    </div>

                    {/* Secondary Information: Bus Plate, Reference, and Secret Code */}
                    <div className="mt-4">
                        <PlateCard plate={ticket.bus_plate} />
                    </div>
                    <div className="mt-4">

                        <TicketListCard ticket={ticket} hideSensitive={hideSensitive} />
                    </div>

                    <div className="text-center mt-2">
                    <Typography variant="body2" color="text.secondary" className="mt-4" style={{
                        fontSize: '0.7em'
                    
                    }}>
                        Present ticket to a HitchVibe staff to board the bus.
                    </Typography>
                    </div>
                </CardContent>
            </Card>
            </div>
        // </motion.div>
    );
}
