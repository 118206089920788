import { useState, useEffect } from "react"
import { Card, CardContent, CardMedia, Typography, Box, Button, Alert } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { getEvents, getRouteSummaries, getAvailableSeats, createBooking, checkBroadcast, addBroadcast } from '../networking/utils';
import Loading from './Loading';
import moment from 'moment';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { motion } from "framer-motion";

function Home(props) {
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedRoute, setSelectedRoute] = useState(null)
  const [selectedBusRoute, setSelectedBusRoute] = useState(null)
  const [selectedJourney, setSelectedJourney] = useState(null)
  const [selectedTimeslot, setSelectedTimeslot] = useState(null)
  const [journeyDetails, setJourneyDetails] = useState(null)
  const [journayMaxPax, setJourneyMaxPax] = useState(1)
  const [journeyPax, setJourneyPax] = useState(1)
  const [isSelectingDate, setIsSelectingDate] = useState(true)
  const [isSelectingLocation, setIsSelectingLocation] = useState(false)
  const [isSelectingPax, setIsSelectingPax] = useState(false)
  const [isOnSale, setIsOnSale] = useState(false)

  const [token, setToken] = useState(null)
  useEffect(() => {
    props.setUseLocalStorage(true)
    if (props.currentTab >= 0) {
      props.setCurrentTab(0)
    }
  }, [])


  useEffect(() => {
    if (props.token && props.token != '') {
      setToken(props.token)
    }
  }, [props.token])

  useEffect(() => {
    if (selectedEvent && selectedDate) {
      setIsSelectingDate(false)
      setIsSelectingLocation(true)
    }
  }, [selectedEvent, selectedDate])

  return (
    <div>

      {
        !token || token == '' &&
        <Loading />
      }
      {
        token && token != '' &&
        <div>
          {
            isSelectingDate &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <DateSelectionComponent
                token={token}
                setSelectedEvent={setSelectedEvent}
                setSelectedDate={setSelectedDate}
              />
            </motion.div>
          }
          {
            isSelectingLocation && selectedEvent && selectedDate &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <SelectLocationComponent
                token={token}
                selectedDate={selectedDate}
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
                setSelectedDate={setSelectedDate}
                setJourneyDetails={setJourneyDetails}
                selectedBusRoute={selectedBusRoute}
                setIsSelectingDate={setIsSelectingDate}
                setIsSelectingLocation={setIsSelectingLocation}
                setIsSelectingPax={setIsSelectingPax}
                setSelectedBusRoute={setSelectedBusRoute}
                setSelectedRoute={setSelectedRoute}
                setSelectedTimeslot={setSelectedTimeslot}
                setSelectedJourney={setSelectedJourney}
              />
            </motion.div>
          }
          {
            isSelectingPax && selectedEvent && selectedDate && selectedRoute && selectedJourney &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <SelectPaxComponent
                token={token}
                selectedDate={selectedDate}
                selectedEvent={selectedEvent}
                selectedRoute={selectedRoute}
                selectedJourney={selectedJourney}
                selectedTimeslot={selectedTimeslot}
                journeyPax={journeyPax}
                journeyMaxPax={journayMaxPax}
                selectedBusRoute={selectedBusRoute}
                setSelectedEvent={setSelectedEvent}
                setSelectedDate={setSelectedDate}
                setIsSelectingDate={setIsSelectingDate}
                setIsSelectingLocation={setIsSelectingLocation}
                setIsSelectingPax={setIsSelectingPax}
                journeyDetails={journeyDetails}
                setSelectedBusRoute={setSelectedBusRoute}
                setSelectedRoute={setSelectedRoute}
                setSelectedJourney={setSelectedJourney}
                setJourneyMaxPax={setJourneyMaxPax}
                setJourneyPax={setJourneyPax}
                setIsOnSale={setIsOnSale}
                isOnSale={isOnSale}
              />
            </motion.div>
          }
        </div>
      }
    </div>
  )
}

export default Home

function DateSelectionComponent(props) {
  const [events, setEvents] = useState(null)
  const [fetchingEvents, setFetchingEvents] = useState(true)
  const [fetchError, setFetchError] = useState(false)
  const fetchEvents = async () => {
    setFetchingEvents(true)
    // console.log('fetching events with token', props.token)
    let events = await getEvents(props.token).then((events) => {
      // console.log('fetched events:', events)
      if (events) {
        setEvents(events.events)
        setFetchError(false)
      } else {
        setFetchError(true)
      }
      setFetchingEvents(false)
      return events
    }).catch((error) => {
      setFetchError(true)
      setFetchingEvents(false)
      console.error('error fetching events:', error)
    })
    return events
  }

  useEffect(() => {
    let events = fetchEvents()
  }, [])

  useEffect(() => {
    if (events) {
      // console.log('events:', events)
    }
  }, [events])

  return (
    // return a 2 col grid of possible dates, grouped by events
    // center everything
    <>

      {
        fetchingEvents &&
        <Loading />
      }

      {
        fetchError && !fetchingEvents &&
        <Box className="flex flex-col gap-4 px-4 py-6">

          <div style={{
            padding: '2em',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <h3 style={{
              textAlign: 'center',
              marginBottom: '1em',
              color: '#333333'
            }}>
              Error fetching events
            </h3>
            <Button
              variant="contained"
              color="primary"
              onClick={() => fetchEvents()}
              style={{
                maxWidth: '200px',
              }}
            >
              Retry
            </Button>
          </div>
        </Box>
      }

      {
        !fetchingEvents && !fetchError && events &&
        <div className="w-full px-4 py-0">
          <div className="flex flex-col p-4">
            {/* <div 
           className="cursor-pointer px-4 py-2 rounded-lg bg-gray-100 text-gray-500 text-xs text-center mb-3"
           variant="outlined" 
           color="primary" 
           onClick={() => {
             props.setSelectedEvent(null)
             props.setSelectedDate(null)
             props.setIsSelectingDate(true)
             props.setIsSelectingLocation(false)
           }}>
             <Typography variant="subtitle1" component="div" fontWeight={800} fontSize={11}>
               « Change Date
             </Typography>
           </div> */}
            <Typography variant="h5" component="div" fontWeight={800}>
              Book your HitchVibe 🚌
            </Typography>
            <Typography variant="subtitle1" component="div" fontWeight={800}>
              Step 1 of 4 - Select an event and date
            </Typography>
          </div>
          {
            /*
            {
              "events": [
                {
                  "event_dates": ["02-03-2024", "03-03-2024", "04-03-2024", "07-03-2024", "08-03-2024", "09-03-2024"],
                  "event_image": "",
                  "id": 1,
                  "is_active": true,
                  "is_past": false,
                  "title": "Taylor Swift: The Eras Tour"
                }
              ]
            }
            */
            events.map((event, index) => {
              return (
                // card for event
                <Card key={index}
                  raised
                  style={{ borderRadius: '0.7em' }}
                  className="w-full">
                  <CardMedia
                    component="img"
                    alt={"Taylor Swift: The Eras Tour"}
                    image={"https://media.allure.com/photos/64186eefab6568bc4143ed09/3:2/w_3396,h_2264,c_limit/taylor%20swift%20hair%20magic%20eras%20tour.jpg"}
                    sx={{
                      objectFit: "cover",
                      objectPosition: "0px -30px",
                      height: 140,
                    }}
                  />
                  <CardContent>
                    <Box className="flex flex-col gap-4 px-1 py-1">
                      <Box className="flex justify-between items-center">
                        <Typography variant="h6" component="div" className="font-semibold">
                          {event.title}
                        </Typography>
                        {/* <Typography className="text-gray-500 text-sm">
                          {event.is_active ? 'Active' : 'Inactive'}
                        </Typography> */}
                      </Box>
                      <div className="all-dates-wrapper">
                        {
                          event.event_dates.map((date, index) => {
                            if (date.is_hidden) {
                              return
                            } else if (date.is_disabled) {
                              return (
                                <div key={index} className="m-2">
                                  <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    className="date-wrapper flex flex-col items-center p-2 bg-gray-100 rounded-lg cursor-not-allowed"
                                  >
                                    <Typography className="text-xs">{moment(date.date, "DD-MM-YYYY").format('D MMMM')} {moment(date.date, "DD-MM-YYYY").format('(ddd)')}</Typography>
                                    <Typography className="text-xs">No rides</Typography>
                                  </motion.div>
                                  {/* add spacer */}
                                  {index < event.event_dates.length - 1 &&
                                    <div className="spacer h-2 w-2 bg-transparent"></div>
                                  }
                                </div>
                              )
                            }
                            return (
                              <div key={index} className="m-2">
                                <motion.div
                                  whileHover={{ scale: 1.05 }}
                                  whileTap={{ scale: 0.95 }}
                                  className="date-wrapper flex flex-col items-center p-2 bg-gray-100 rounded-lg cursor-pointer"
                                  onClick={() => {
                                    console.log("setting selected event & date", event, date.date)
                                    props.setSelectedEvent(event.id)
                                    props.setSelectedDate(date.date)
                                  }}
                                >
                                  <Typography className="text-xs">{moment(date.date, "DD-MM-YYYY").format('D MMMM')} {moment(date.date, "DD-MM-YYYY").format('(ddd)')}, {date.night}</Typography>
                                  <Typography className="text-xs"></Typography>
                                </motion.div>
                                {/* add spacer */}
                                {index < event.event_dates.length - 1 &&
                                  <div className="spacer h-2 w-2 bg-transparent"></div>
                                }
                              </div>
                            )
                          })
                        }
                      </div>
                    </Box>
                  </CardContent>
                </Card>
              )
            })
          }
        </div>
      }
    </>
  )
}


function WaitlistComponent(props) {
  const [isSoldOut, setIsSoldOut] = useState(false)
  const [isSubscribingNotifications, setIsSubscribingNotifications] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)


  useEffect(() => {

    const refreshBroadcasts = async () => {
      // if all busRoutes are sold out, then set isSoldOut to true
      let soldOut = true;
      let subscribing = false;
      let subscribed = false;
      let routeSoldOut = true;
      props.busRouteTimings.forEach((timing) => {
        if (!timing.is_fully_booked) {
          soldOut = false;
        }
      });
      // console.log("checking broadcasts for route:", busRoutes[i]);
      let response = await checkBroadcast(props.token, props.selectedDate, props.selectedRoute);
      if (response) {
        // console.log("response for checkBroadcasts:", response);
        subscribed = response.in_broadcast_list;
      }

      setIsSoldOut(soldOut);
      setIsSubscribingNotifications(subscribing);
      setIsSubscribed(subscribed);
    }

    refreshBroadcasts()
  }, [props.selectedDate, props.selectedEvent])

  useEffect(() => {
    if (props.isSoldOut) {
      setIsSoldOut(props.isSoldOut)
    }
  }, [props.isSoldOut])

  useEffect(() => {
    if (props.isSubscribingNotifications) {
      setIsSubscribingNotifications(props.isSubscribingNotifications)
    }
  }, [props.isSubscribingNotifications])

  useEffect(() => {
    if (props.isSubscribed) {
      setIsSubscribed(props.isSubscribed)
    }
  }, [props.isSubscribed])

  return (
    <>
      {isSoldOut &&
        <div className="w-full">
          {
            isSoldOut && isSubscribingNotifications &&
            <div className="w-full">
              <LoadingButton className="w-full" loading>
                Waitlisting...
              </LoadingButton>
            </div>
          }
          {
            isSoldOut && !isSubscribingNotifications && !isSubscribed &&
            <Button
              className="w-full"
              variant="outlined"
              color="primary"
              style={{
                borderRadius: '0.75em'
              }}
              onClick={() => {
                setIsSubscribingNotifications(true)
                const subscribe = async () => {
                  const response = await addBroadcast(props.token, props.selectedDate, props.selectedRoute)
                  if (response) {
                    setIsSubscribed(true)
                    setIsSubscribingNotifications(false)
                  } else {
                    setIsSubscribingNotifications(false)
                  }
                }
                subscribe()
              }}
            >

              <><NotificationAddIcon className="text-md mr-2" fontSize="0.8em" /> Notify Me</>

            </Button>
          }
          {
            isSoldOut && !isSubscribingNotifications && isSubscribed &&
            <div className="flex flex-col">
              <Button
                className="w-full"
                variant="outlined"
                disabled
                color="primary"
                style={{
                  borderRadius: '0.75em'
                }}
              >
                <div><NotificationsNoneIcon className="text-md mr-2" fontSize="0.8em" /> In Waitlist</div>
              </Button>
              <Typography
                style={{
                  fontSize: '0.6rem'
                }}
                className="text-xs text-center text-gray-500 pt-1"
              >
                You will be notified via Telegram when more timeslots open up.
              </Typography>
            </div>
          }
        </div>
      }
    </>
  )
}

function SelectLocationComponent(props) {
  // busRoutes given a specific event and date
  // const busRoutes = []

  const [busRoutes, setBusRoutes] = useState([])
  const [fetchingBusRoutes, setFetchingBusRoutes] = useState(true)
  const [fetchError, setFetchError] = useState(false)

  const fetchBusRoutes = async () => {
    setFetchingBusRoutes(true)
    // console.log('fetching busroutes with token', props.token)
    let busroutes = await getRouteSummaries(props.token, props.selectedEvent, props.selectedDate).then((busroutes) => {
      // console.log('fetched busroutes:', busroutes)
      if (busroutes) {
        setBusRoutes(busroutes)
        setFetchError(false)
      } else {
        setFetchError(true)
      }
      setFetchingBusRoutes(false)
      return busroutes
    }).catch((error) => {
      setFetchError(true)
      setFetchingBusRoutes(false)
      console.error('error fetching busroutes:', error)
    })
    return busroutes
  }


  useEffect(() => {
    let events = fetchBusRoutes()
  }, [])

  return (
    <div className="w-full px-4 py-0" key={props.selectedEvent}>
      <div className="flex flex-col px-4 pt-4">
        <div
          className="cursor-pointer px-4 py-2 rounded-lg bg-gray-100 text-gray-500 text-xs text-center mb-3"
          variant="outlined"
          color="primary"
          onClick={() => {
            props.setSelectedEvent(null)
            props.setSelectedDate(null)
            props.setIsSelectingDate(true)
            props.setIsSelectingLocation(false)
          }}>
          <Typography variant="subtitle1" component="div" fontWeight={800} fontSize={11}>
            « Change Date
          </Typography>
        </div>
        <Typography variant="h5" component="div" fontWeight={800}>
          Rides on {moment(props.selectedDate, "DD-MM-YYYY").format('D MMMM')} {moment(props.selectedDate, "DD-MM-YYYY").format('(ddd)')}
        </Typography>
        <Typography variant="subtitle1" component="div" fontWeight={800} style={{
          fontSize: '0.9rem'
        }}>
          Step 2 of 4 - Select your departure timeslot
        </Typography>
        
        {/* https://www.google.com/maps/dir/1.3034123,103.8731675/1.301772,103.8799625/@1.3023436,103.8745994,863m/data=!3m1!1e3!4m2!4m1!3e2?entry=ttu */}
        <motion.div 
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="cursor-pointer my-1"
          onClick={() => {
            window.open(`https://maps.app.goo.gl/Yej1VG1BQekypCfu6`)
          }}
        >
        <div className="px-2 pb-1 bg-slate-400 items-center text-center rounded-lg text-white">
          <Typography variant="p" fontWeight={800} style={{
            fontSize: '0.7rem'
          }}>
              Directions from Stadium to HitchVibe Pickup 
          </Typography>
        </div>
        </motion.div>

        <Alert severity="info" className="mt-2" style={{
          fontSize: '0.8rem'
        }}>
          {/* <AlertTitle>Info</AlertTitle> */}
          <Typography variant="p" fontWeight={800}>
            Departure times are tentative, and are subjected to change to approximately 45 minutes after the event ends.
          </Typography>
        </Alert>

        {/* <motion.div 
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="cursor-pointer my-1"
          onClick={() => {
            window.open(`https://www.google.com/maps/dir/1.3034123,103.8731675/1.301772,103.8799625/@1.3023436,103.8745994,863m/data=!3m1!1e3!4m2!4m1!3e2?entry=ttu`)
          }}
        >
        <div className="px-2 pb-1 bg-slate-400 items-center text-center rounded-lg text-white">
          <Typography variant="p" fontWeight={800} style={{
            fontSize: '0.7rem'
          }}>
              Suggest a new route
          </Typography>
        </div>
        </motion.div> */}
      </div>
      {busRoutes && busRoutes.length > 0 &&
        <Box className="flex flex-col gap-4 px-4 py-4">
          {

            busRoutes.map((busRoute, index) => {
              return (
                <Card className="rounded-lg" key={index} style={{ marginTop: 0, borderRadius: '0.7em' }} raised>
                  <CardContent style={{
                    paddingBottom: '16px'
                  }}>
                    <Box className="flex flex-col gap-1">
                      <Box className="flex flex-col">
                        <Box className="flex items-center gap-2">
                          <Typography variant="subtitle1" component="div" fontWeight={700}>
                            {
                              busRoute.pickup_location
                            }
                            {" » "}
                            {
                              busRoute.dropoff_location
                            }
                          </Typography>
                          {
                            busRoute.dropoff_station_codes.map((station, index) => {
                              let lineColour = station.color

                              return (
                                <Box key={index}
                                  className={("text-white font-bold px-2.5 py-0.5 rounded-full text-xs")}
                                  style={{
                                    backgroundColor: lineColour,
                                  }}>
                                  {station.line}
                                </Box>
                              )
                            })
                          }
                        </Box>
                      </Box>

                      <WaitlistComponent
                        token={props.token}
                        selectedDate={props.selectedDate}
                        selectedRoute={busRoute.route_id}
                        busRouteTimings={busRoute.timings}
                      />

                      <Box className="mt-0">
                        <Typography className="text-gray-500 text-xs" fontSize={14}>Departure Times</Typography>
                        <Box className="timings-container"> {/* Use grid layout */}
                          {/* Departure times */}
                          <div className="timings-grid">

                            {busRoute.timings.map((journey, index) => {
                              let pillColour = "bg-gray-100 text-gray-500" // fully booked
                              let availabilityTextColour = "text-gray-500"
                              let availabilityText = ""
                              let isFullyBooked = false
                              if (journey.is_disabled) {
                                return
                              } else if (journey.is_archived) {
                                availabilityTextColour = "text-gray-500"
                                availabilityText = "Not Available"
                                isFullyBooked = true
                              } else if (!journey.is_fully_booked) {
                                // if capacity indicator is 0, then green, else if 1, then yellow, else if 2 orange, else red. change text
                                if (journey.capacity_indicator == 0) {
                                  availabilityTextColour = "text-green-500"
                                  availabilityText = "Available"
                                } else if (journey.capacity_indicator == 1) {
                                  availabilityTextColour = "text-yellow-500"
                                  availabilityText = "Selling Fast"
                                } else if (journey.capacity_indicator == 2) {
                                  availabilityTextColour = "text-orange-500"
                                  availabilityText = "Limited Seats"
                                } else if (journey.capacity_indicator == 3) {
                                  availabilityTextColour = "text-red-500"
                                  availabilityText = "Last Seats"
                                } else {
                                  availabilityTextColour = "text-gray-500"
                                  availabilityText = "Sold Out"
                                }
                              } else {
                                availabilityTextColour = "text-gray-800"
                                availabilityText = "Sold Out"
                                isFullyBooked = true
                              }
                              return (
                                // each timing in its own pill, but can overflow to next line

                                <motion.div
                                  key={index}
                                  whileHover={{ scale: isFullyBooked ? 1 : 1.05 }}
                                  whileTap={{ scale: isFullyBooked ? 1 : 0.95 }}
                                  className={(isFullyBooked ? "cursor-not-allowed" : "cursor-pointer") + " timing-pill"}
                                  onClick={() => {
                                    if (isFullyBooked) {
                                      return
                                    }
                                    props.setSelectedBusRoute(busRoute)
                                    props.setSelectedRoute(busRoute.route_id)
                                    props.setSelectedJourney(journey.journey_id)
                                    props.setSelectedTimeslot(journey.departure_time)
                                    props.setJourneyDetails(journey)
                                    props.setIsSelectingLocation(false)
                                    props.setIsSelectingPax(true)
                                  }}
                                >
                                  <div className={("timing px-2 py-1 rounded-lg text-xs " + pillColour)}
                                    key={index}>
                                    <div style={{ display: 'inline-block' }}>
                                      <Typography
                                        className="text-xs text-center"
                                        fontWeight={(isFullyBooked ? 500 : 800)}
                                      >
                                        {journey.departure_time}
                                      </Typography>
                                      <hr />
                                    </div>
                                    <Typography
                                      className={("text-xs text-center " + availabilityTextColour)}
                                      style={{ fontSize: '0.6rem' }}
                                      fontWeight={(isFullyBooked ? 500 : 800)}
                                    >
                                      {availabilityText}
                                    </Typography>
                                  </div>
                                </motion.div>
                              )
                            }
                            )
                            }
                          </div>
                        </Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>)
            })
          }

        </Box>}

      {
        (fetchingBusRoutes || !busRoutes) && <Loading />
      }

      {
        busRoutes && busRoutes.length == 0 && !fetchingBusRoutes &&
        <Box className="flex flex-col gap-4 px-4 py-6">
          <div style={{
            padding: '2em',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <h3 style={{
              textAlign: 'center',
              marginBottom: '1em',
              color: '#333333'
            }}>
              {
                fetchError ? 'Error fetching bus routes, please refresh.' : 'No bus routes available for this date.'
              }
            </h3>
          </div>
        </Box>
      }
    </div>
  );
}



function SelectPaxComponent(props) {
  const [loadText, setLoadText] = useState("Loading..")
  const [journeyDetails, setJourneyDetails] = useState(null)
  const [fetchingJourneyDetails, setFetchingJourneyDetails] = useState(true)
  const [fetchError, setFetchError] = useState(false)
  const fetchJourneyDetails = async () => {
    // call getAvailableSeats
    setFetchingJourneyDetails(true)
    // console.log('fetching journey details with token', props.token)
    let journeyDetails = await getAvailableSeats(props.token, props.selectedJourney).then((journeyDetails) => {
      // console.log('fetched journey details:', journeyDetails)
      if (journeyDetails) {
        setJourneyDetails(journeyDetails)
        // console.log("response for available seats: ", journeyDetails)
        setFetchingJourneyDetails(false)
        setFetchError(false)
      } else {
        setFetchError(true)
        setFetchingJourneyDetails(false)
      }
      return journeyDetails
    }).catch((error) => {
      setFetchError(true)
      setFetchingJourneyDetails(false)
      console.error('error fetching journey details:', error)
    })
  }

  useEffect(() => {
    fetchJourneyDetails()
  }, [])


  useEffect(() => {
    if (journeyDetails) {
      props.setJourneyMaxPax(journeyDetails.available_seats)
      props.setIsOnSale(journeyDetails.is_on_sale)
    }
  }, [journeyDetails])

  const postBooking = async () => {
    await createBooking(props.token, props.selectedJourney, props.journeyPax).then((booking) => {
      if (booking) {
        // console.log('booking response:', booking)
        // setFetchingJourneyDetails(false)
        window.location.href = booking.stripe_checkout_link
      } else {
        fetchJourneyDetails()
        console.error('error posting booking:', booking)
        setFetchingJourneyDetails(false)
      }
    }).catch((error) => {
      fetchJourneyDetails()
      console.error('error posting booking:', error)
      setFetchingJourneyDetails(false)
    })
  }

  return (
    <div className="w-full px-4 py-0 overflow-clip" key={props.selectedEvent}>
      {!fetchingJourneyDetails && journeyDetails && journeyDetails.available_seats > 0 &&
        <div className="flex flex-col justify-between h-screen " style={{ height: 'calc(100vh - 6.5em)' }}>
          {/* top elements */}
          <div className="flex flex-col px-4 pt-4">
            <div
              className="cursor-pointer px-4 py-2 rounded-lg bg-gray-100 text-gray-500 text-xs text-center mb-3"
              variant="outlined"
              color="primary"
              onClick={() => {
                props.setIsSelectingPax(1)
                props.setJourneyMaxPax(1)
                props.setIsSelectingLocation(true)
                props.setIsSelectingPax(false)
              }}>
              <Typography variant="subtitle1" component="div" fontWeight={800} fontSize={11}>
                « Change Timeslot
              </Typography>
            </div>
            <Typography variant="h5" component="div" fontWeight={800}>
              Your HitchVibe Ride
            </Typography>
            <Typography variant="subtitle1" component="div" fontWeight={800}>
              Step 3 of 4 - How many passengers?
            </Typography>

            <Card style={{ borderRadius: '0.7em' }} className="mt-3" raised>
              <CardContent>
                <div className="p-4 rounded-lg bg-gray-100 text-gray-500 my-4 flex flex-col">
                  <div className="w-full flex justify-between text-center mb-3">
                    {/* Pickup Location */}
                    <Typography variant="p" fontWeight={500}>
                      {props.selectedBusRoute.pickup_location}
                    </Typography>

                    {/* Route Symbol */}
                    <Typography variant="p" fontWeight={600}>
                      {" »"}
                    </Typography>

                    {/* Dropoff Location */}
                    <Typography variant="p" fontWeight={500}>
                      {props.selectedBusRoute.dropoff_location}
                    </Typography>
                  </div>

                  <div
                    className="flex justify-between items-center"
                  >
                    <Typography variant="p" fontWeight={700}>
                      {moment(props.selectedDate, "DD-MM-YYYY").format('D MMMM')} {moment(props.selectedDate, "DD-MM-YYYY").format('(ddd)')}
                    </Typography>
                    <Typography variant="p" fontWeight={700}>
                      {props.selectedTimeslot}
                    </Typography>
                  </div>
                </div>
                {/* similar to the above div, but 3 sections, left is a minus button, middle is a number, annd right is a plus button, split in the proportions 20-60-20, adjusted the journeypax, and disables minus button when pax is 1, disables the plus is it is equals max pax */}
                <div className="flex justify-between items-center p-4 rounded-lg bg-gray-100 text-gray-500">
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={props.journeyPax == 1}
                    onClick={() => {
                      if (props.journeyPax > 1) {
                        props.setJourneyPax(props.journeyPax - 1)
                      }
                    }}
                    style={{
                      maxWidth: '50px',
                      borderRadius: '25px'
                    }}
                  >
                    -
                  </Button>
                  <Typography variant="p" className="text-gray-500" fontWeight={600}>
                    {props.journeyPax} pax
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={props.journeyPax == props.journeyMaxPax}
                    onClick={() => {
                      if (props.journeyPax < props.journeyMaxPax) {
                        props.setJourneyPax(props.journeyPax + 1)
                      }
                    }}
                    style={{
                      maxWidth: '50px',
                      borderRadius: '25px'
                    }}
                  >
                    +
                  </Button>
                </div>

                {/* ensure journeyMaxPax as a string of number can be casted to a number before comparing */}
                { props.journeyMaxPax < 10 &&
                  <div className="w-full text-center items-center mt-2">
                    <Typography variant="p" className="text-red-600" style={{
                      fontSize: '0.8rem'
                    }} fontWeight={800}>
                      are you still swift af boi? just {props.journeyMaxPax} seat{(props.journeyMaxPax == 1 ? "" : "s")} left!
                    </Typography>
                  </div>
                }

              </CardContent>
            </Card>
          </div>


          {/* bottom elements */}
          {/* same banner style, but  */}
          <div className="flex flex-col px-4 pt-0">
            <div
              className="flex justify-between items-center p-4 rounded-lg bg-gray-100 text-gray-500 my-4"
            >
              <Typography variant="p" fontWeight={800}>
                Total Cost
              </Typography>

              {props.isOnSale ?
                <div>
                  <Typography variant="p" fontWeight={300} style={{
                    marginRight: '0.3em',
                    textDecoration: 'line-through',
                  }}>
                    ${(props.journeyDetails.ticket_price * props.journeyPax).toFixed(2)}
                  </Typography>
                  <Typography variant="p" fontWeight={700}>
                    ${(props.journeyDetails.ticket_price * props.journeyPax - (2 * props.journeyPax)).toFixed(2)}
                  </Typography>
                </div>
                :
                <Typography variant="p" fontWeight={600}>
                  ${(props.journeyDetails.ticket_price * props.journeyPax).toFixed(2)}
                </Typography>
              }
            </div>

            <div className="flex flex-col">
              <Button
                variant="contained"
                color="primary"
                style={{ borderRadius: '0.7em' }}
                onClick={async () => {
                  setFetchingJourneyDetails(true)
                  setLoadText("Redirecting to checkout..")
                  await postBooking()
                }}
              >
                Confirm Booking & Pay
              </Button>
              <Typography variant="p" className="text-gray-500 text-xs text-center pt-2">
                By clicking this button, you agree to our terms and conditions.
              </Typography>
            </div>
          </div>
        </div>
      }

      {
        !fetchingJourneyDetails && journeyDetails && journeyDetails.available_seats == 0 &&
        <Box className="flex flex-col gap-4 px-4 py-6">
          <div style={{
            padding: '2em',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <h3 style={{
              textAlign: 'center',
              marginBottom: '1em',
              color: '#333333'
            }}>
              <p>Sorry, there are no more seats available for this ride.</p>
            </h3>

            <div
              className="cursor-pointer px-4 py-2 rounded-lg bg-gray-100 text-gray-500 text-xs text-center mb-3"
              variant="outlined"
              color="primary"
              onClick={() => {
                props.setIsSelectingPax(1)
                props.setJourneyMaxPax(1)
                props.setIsSelectingLocation(true)
                props.setIsSelectingPax(false)
              }}>
              <Typography variant="subtitle1" component="div" fontWeight={800} fontSize={11}>
                « Change Timeslot
              </Typography>
            </div>
          </div>
        </Box>
      }

      {
        (fetchingJourneyDetails || !journeyDetails) && <Loading loadText={
          loadText
        } />
      }

      {
        journeyDetails && journeyDetails.length == 0 && !fetchingJourneyDetails &&
        <Box className="flex flex-col gap-4 px-4 py-6">
          <div style={{
            padding: '2em',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <h3 style={{
              textAlign: 'center',
              marginBottom: '1em',
              color: '#333333'
            }}>
              {
                fetchError ? 'Error fetching journey details, please refresh.' : 'Something went wrong, please refresh.'
              }
            </h3>
          </div>
        </Box>
      }
    </div>
  )
}
