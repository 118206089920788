import { useState, useEffect } from "react"
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Input from '@mui/joy/Input';
import Snackbar from '@mui/joy/Snackbar';
import { CircularProgress } from '@mui/material';
import { getJourneysForStaff, getRouteNames } from '../networking/utils';
import Loading from './Loading';
import moment from 'moment';
import axios from 'axios';
import { motion } from "framer-motion";
import BookingListCard from "./BookingListCard";
import mrtcrowd from "../assets/mrtcrowd.png";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LinearProgress from '@mui/material/LinearProgress';
import DepartureIdComponent from "./DepartureIdComponent";
import PlateCard from "./PlateCard";


function StaffHomePage(props) {
    const navigate = useNavigate();
    const [token, setToken] = useState(null)
    const [fetching, setFetching] = useState(false)
    const [parsedData, setParsedData] = useState([])
    const [fetchError, setFetchError] = useState(true)
    const [staffByDateInput, setStaffByDateInput] = useState({})
    const [searchQuery, setSearchQuery] = useState('')
    const [routeMapping, setRouteMapping] = useState({})


    const fetchStaffJourneys = async () => {
        try {
            
            const routeResponse = await getRouteNames(props.token).then((response) => {
                let routeMapping = {}
                response.routes.forEach((route) => {
                    routeMapping[route._id] = route
                })
                console.log("route mapping:", routeMapping)
                setRouteMapping(routeMapping)
            }).catch((error) => {
                console.error('error fetching route names:', error)
                setFetchError(true)
                setFetching(false)
            })
            const response = await getJourneysForStaff(props.token).then((response) => {
                console.log("all journeys:", response)
                let journeys = response.journeys.filter((journey) => {
                    return journey.is_archived == false
                })
                // sort by date, then by time
                journeys.sort((a, b) => {
                    let aDate = new Date(a.date)
                    let bDate = new Date(b.date)
                    let aTime = new Date(a.time)
                    let bTime = new Date(b.time)
                    if (aDate > bDate) {
                        return 1
                    } else if (aDate < bDate) {
                        return -1
                    } else {
                        if (aTime > bTime) {
                            return 1
                        } else if (aTime < bTime) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                })
                setParsedData(journeys)
                setFetching(false)
            }).catch((error) => {
                console.error('error fetching all journeys:', error)
                setFetchError(true)
                setFetching(false)
            })
        } catch (error) {
            console.error('error fetching all journeys:', error)
            setFetchError(true)
            setFetching(false)
        }
    }

    useEffect(() => {
        if (token && token != '') {
            setFetching(true)
            setFetchError(false)
            fetchStaffJourneys()
        }
    }, [token])

    useEffect(() => {
        props.setUseLocalStorage(true)
        if (props.currentTab >= 0) {
            props.setCurrentTab(props.tabMappingIndex)
        }
    }, [])


    useEffect(() => {
        if (props.token && props.token != '') {
            setToken(props.token)
        }
    }, [props.token])
    return (
        <div>

            {
                !token || token == '' || fetching &&
                <Loading />
            }

            {
                !fetching && !fetchError && parsedData && parsedData.length == 0 &&
                <Box className="flex flex-col gap-4 text-center mt-12 p-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* 
                    <Lottie
                        animationData={busLoadAnimation}
                        loop={true}
                        style={{
                            width: 200,
                            height: 200

                        }}
                    /> */}
                    <Typography variant="p" className="text-gray-400" fontWeight={800}>
                        No active journeys
                    </Typography>
                </Box>
            }


            {
                fetchError && !fetching &&
                <Box className="flex flex-col gap-4 px-4 py-6">

                    <div style={{
                        padding: '2em',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <h3 style={{
                            textAlign: 'center',
                            marginBottom: '1em',
                            color: '#333333'
                        }}>
                            Error fetching bookings
                        </h3>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setFetching(true)
                                setTimeout(() => {
                                    fetchStaffJourneys()
                                }, 1300)
                            }
                            }
                            style={{
                                maxWidth: '200px',
                            }}
                        >
                            Retry
                        </Button>
                    </div>
                </Box>
            }

            {
                !fetching && !fetchError && parsedData && parsedData.length > 0 &&
                <div className="w-screen p-4">
                    <Card className="w-full p-4">
                        <CardContent>
                            <div className="flex flex-col">
                            <Typography variant="h6" fontWeight={800} className="mb-4">
                                Select a ride
                            </Typography>
                            <div className="w-full">
                                <input type="text" placeholder="Search 4-digit ID" className="w-full p-2 rounded-lg border-2 border-gray-300 mb-3" onChange={(e) => {
                                    setSearchQuery(e.target.value)
                                }
                                } />
                            </div>
                            </div>
                            <div className="flex flex-col gap-4">
                                {
                                    parsedData.map((journey, index) => {
                                        if (searchQuery != '') {
                                            if (!(journey._id).slice(-4).toUpperCase().startsWith(searchQuery.toUpperCase())) {
                                                return null
                                            }
                                        }
                                        return (
                                            <Card className="my-1 cursor-pointer" raised style={{
                                                borderRadius: '0.7em'
                                            }}
                                            onClick={() => {
                                                navigate(`/staff-journey?jid=${journey._id}`)
                                            }}
                                            >
                                                {/* show, time, bus_plate, last 4 digits of _Id */}
                                                <CardContent>
                                                    <div className="flex justify-between">
                                                        <div className="flex flex-col">
                                                            <Typography variant="p" fontWeight={800} className="mb-2">
                                                                {routeMapping[journey.route_id] && routeMapping[journey.route_id].dropoff_stop}
                                                            </Typography>
                                                            <Typography variant="p" fontWeight={400} className="mb-2">
                                                                {journey.date}
                                                            </Typography>
                                                            <Typography variant="p" fontWeight={400} className="mb-2">
                                                                {journey.timing}
                                                            </Typography>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <div className="bg-gray-900 text-white p-1 rounded-lg shadow-2xl border-2 mb-2 border-black flex justify-center items-center">
                                                                <Typography variant="p" fontWeight={800} className="mb-2">
                                                                    {journey.bus_plate}
                                                                </Typography>
                                                            </div>
                                                            <DepartureIdComponent departureId={journey._id} />
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        )
                                    })
                                }
                            </div>
                        </CardContent>
                    </Card>
                </div>
            }

        </div>
    )
}


function BoardingPill() {
    return (

        <div>
            <div className="bg-cyan-500 text-cyan-100 rounded-full px-2 py-0.5 text-center flex items-center mr-1">

                <Typography variant="p" fontWeight={700} style={{
                    fontSize: '0.65em'
                }}>
                    Boarding
                </Typography>
            </div>
        </div>
    )
}


function DepartedPill() {
    return (

        <div>
            <div className="bg-green-500 text-green-100 rounded-full px-2 py-0.5 text-center flex items-center mr-1">
                <Typography variant="p" fontWeight={700} style={{
                    fontSize: '0.65em'
                }}>
                    Departed
                </Typography>
            </div>
        </div>
    )
}


export default StaffHomePage
