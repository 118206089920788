import { useState, useEffect } from "react"
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { getTicketDetails } from '../networking/utils';
import Loading from './Loading';
import moment from 'moment';
import axios from 'axios';
import { AnimatePresence, motion } from "framer-motion";
import BookingListCard from "./BookingListCard";
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ListIcon from '@mui/icons-material/List';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';

import { useSwipeable } from 'react-swipeable';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TicketListCard from "./TicketListCard";
import PlateCard from "./PlateCard";
import TicketCard from "./TicketCard";
import logo from './hitchvibe_logo.png';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
function BookingDetails(props) {
    const navigate = useNavigate();
    let query = useQuery();
    // get query params for jid and rid
    const jid = query.get("jid");
    const rid = query.get("rid");
    const [token, setToken] = useState(null)
    const [fetchingTicketDetails, setFetchingTicketDetails] = useState(false)
    const [ticketDetails, setTicketDetails] = useState([])
    const [fetchError, setFetchError] = useState(false)
    const [hideSensitive, setHideSensitive] = useState(true)
    const [showListView, setShowListView] = useState(false)

    const fetchTicketDetails = async () => {
        try {
            const response = await getTicketDetails(props.token, jid, rid).then((response) => {
                // console.log("ticket details:", response)
                setTicketDetails(response.tickets)
                setFetchingTicketDetails(false)
                setFetchError(false)
            }).catch((error) => {
                console.error('error fetching ticket details:', error)
                setFetchError(true)
                setFetchingTicketDetails(false)
            })
        } catch (error) {
            console.error('error fetching ticket details:', error)
            setFetchError(true)
            setFetchingTicketDetails(false)
        }
    }


    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState(1);

    const paginate = (newDirection) => {
        if (newDirection == 1) {
            const newIndex = Math.max(currentIndex + 1, 0);
            if (newIndex >= ticketDetails.length) {
                return
            }
            setDirection(newIndex < currentIndex ? -1 : 1);
            setCurrentIndex(newIndex);
        } else {
            const newIndex = Math.min(currentIndex - 1, ticketDetails.length - 1);
            if (newIndex < 0) {
                return
            }
            setDirection(newIndex < currentIndex ? -1 : 1);
            setCurrentIndex(newIndex);
        }
    }

    // Handlers for swipe actions
    const handlers = useSwipeable({
        onSwipedLeft: () => {
            paginate(1)
        },
        onSwipedRight: () => {
            paginate(-1)
        },
    });

    // Animation variants for sliding in and out
    const variants = {
        enter: () => ({
            x: direction > 0 ? 500 : -500, // Slide in from right if next, from left if previous
            // y: -35,
            opacity: 0,
            // transition: {
            //     duration: 1
            // }
        }),
        center: {
            zIndex: 1,
            x: 0,
            y: 0,
            opacity: 1,
            transition: {
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 }
            }
        },
        exit: () => ({
            zIndex: 2,
            x: direction < 0 ? 500 : -500, // Slide out to right if next, to left if previous
            y: 0,
            opacity: 0,
            transition: {
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
                // duration: 1000
            },
            
        }),
    };


    useEffect(() => {
        if (token && token != '') {
            setFetchingTicketDetails(true)
            setFetchError(false)
            fetchTicketDetails()
        }
    }, [token])

    useEffect(() => {
        props.setUseLocalStorage(true)
        if (props.currentTab >= 0) {
            props.setCurrentTab(1)
        }
    }, [])


    useEffect(() => {
        if (props.token && props.token != '') {
            setToken(props.token)
        }
    }, [props.token])
    return (
        <div>

            {
                !token || token == '' || fetchingTicketDetails &&
                <Loading />
            }

            {
                !fetchingTicketDetails && !fetchError && ticketDetails && ticketDetails.length == 0 &&
                <Box className="flex flex-col gap-4 px-4 py-6">
                    <div style={{
                        padding: '2em',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <h3 style={{
                            textAlign: 'center',
                            marginBottom: '1em',
                            color: '#333333'
                        }}>
                            No tickets found for this booking
                        </h3>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate('/bookings')}
                            style={{
                                maxWidth: '200px',
                            }}
                        >
                            Go back
                        </Button>
                    </div>
                </Box>
            }


            {
                fetchError && !fetchingTicketDetails &&
                <Box className="flex flex-col gap-4 px-4 py-6">

                    <div style={{
                        padding: '2em',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <h3 style={{
                            textAlign: 'center',
                            marginBottom: '1em',
                            color: '#333333'
                        }}>
                            Error fetching bookings
                        </h3>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => fetchTicketDetails()}
                            style={{
                                maxWidth: '200px',
                            }}
                        >
                            Retry
                        </Button>
                    </div>
                </Box>
            }

            {
                !fetchingTicketDetails && !fetchError && ticketDetails && ticketDetails.length > 0 &&
                <div className="w-full px-4 py-2">
                    <div className="flex flex-col px-2">
                        <div className="mb-2 w-full flex justify-between">
                            <Typography variant="h5" fontWeight={800}>
                                Tickets
                            </Typography>
                            <div className='action-buttons flex gap-x-4'>


                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    {/* toggle list/carousel view */}
                                    <div className="bg-gray-200 rounded-full p-1">
                                        {
                                            showListView ?
                                                <ViewCarouselIcon
                                                    className="text-gray-500"
                                                    onClick={() => setShowListView(false)} />
                                                :
                                                <ListIcon
                                                    className="text-gray-500"
                                                    onClick={() => setShowListView(true)} />
                                        }
                                    </div>
                                </motion.div>

                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    {/* toggle sensitive info */}
                                    <div className="bg-gray-200 rounded-full p-1">
                                        {
                                            hideSensitive ?
                                                <VisibilityOffIcon className="text-gray-500" onClick={() => setHideSensitive(false)} />
                                                :
                                                <VisibilityIcon className="text-gray-500" onClick={() => setHideSensitive(true)} />
                                        }
                                    </div>
                                </motion.div>

                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    onClick={() => navigate('/bookings')}
                                >
                                    {/* grey circle button with close icon*/}
                                    <div className="bg-gray-200 rounded-full p-1">
                                        <CloseIcon className="text-gray-500" />
                                    </div>
                                </motion.div>

                            </div>
                        </div>

                        {
                            showListView &&
                            <>
                                <Card className='w-full my-4' style={{
                                    borderRadius: '0.7em',
                                }} raised>
                                    <CardContent style={{
                                        padding: '1em 0.2em'
                                    }}>
                                        <div className="px-4">
                                            
                                            <div className='py-3 flex justify-between'>
                                                <div>
                                                    <img src={logo} alt="HitchVibe" style={{height: 'auto', width: '9rem'}} />
                                                </div>
                                                <Typography variant="p" className="text-gray-300 text-right" style={{ fontSize: "0.8em" }} fontWeight={1000}>TICKET</Typography>
                                            </div>
                                            <div className='flex justify-between'>
                                                <div className="text-4xl flex flex-col items-center space-x-1 pr-2">
                                                    <span className="font-semibold">{ticketDetails.length}</span>
                                                    <span className="text-sm font-medium tracking-wide">ticket{(ticketDetails.length == 1 ? "" : "s")}</span>
                                                </div>
                                                <div className="grid gap-1 text-sm leading-none pl-2 text-right">
                                                    <p className="truncate">
                                                        <Typography variant="p" fontWeight={800}>
                                                            {ticketDetails[0].destination}
                                                        </Typography>
                                                    </p>
                                                    <p>
                                                        <Typography variant="p" fontWeight={600}>
                                                            {moment(ticketDetails[0].date).format('D MMMM')}
                                                        </Typography>
                                                    </p>
                                                    <p>
                                                        <Typography variant="p" fontWeight={600}>
                                                            {ticketDetails[0].time}
                                                        </Typography>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <PlateCard plate={ticketDetails[0].bus_plate} />
                                            </div>
                                        </div>
                                        <div className="text-center mt-2 px-4">
                                        <Typography variant="body2" color="text.secondary" className="mt-4">
                                            Present ticket{ticketDetails.length == 1 ? "" : "s"} to a HitchVibe staff to board the bus.
                                        </Typography>
                                        </div>
                                    </CardContent>
                                </Card>
                                {
                                    ticketDetails.map((ticket, index) => {
                                        return (
                                            <>
                                                <div>
                                                    <div className="mb-4">
                                                        <TicketListCard ticket={ticket} hideSensitive={hideSensitive} raised={true} />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </>
                        }

                        {!showListView && (
                            <div key={currentIndex} {...handlers} style={{ overflow: 'hidden', width: '100%' }}>
                                <div className="flex" style={{ justifyContent: 'center' }}>

                                    {ticketDetails.map((ticket, index) => {
                                        let isActive = (index == currentIndex);
                                        return (
                                            <>
                                            {isActive &&
                                                <AnimatePresence key={index}>
                                                <motion.div
                                                custom={direction}
                                                key={index+"motion"}
                                                variants={variants}
                                                initial="enter"
                                                animate="center"
                                                exit="exit"
                                                transition={{
                                                    x: { type: "spring", stiffness: 300, damping: 20 },
                                                    opacity: { duration: 0.2 }
                                                }}
                                                style={{
                                                    // paddingTop: '1em',
                                                    // padding: '1em',
                                                    maxWidth: '100%', // Adjust card width
                                                    margin: '0 auto', // Center the card
                                                    position: 'absolute' // Use absolute to overlay cards
                                                }}
                                                > 
                                                <TicketCard 
                                                key={index+"card"}
                                                ticket={ticketDetails[currentIndex]} hideSensitive={hideSensitive} 
                                                />
                                            </motion.div></AnimatePresence>
                                            }
                                            </>
                                        );
                                    })}
                                </div>

                                {/* Navigation buttons */}
                                <div className="fixed bottom-16 left-4 right-4 flex justify-between items-center z-20" onClick={(e)=>{
                                    e.preventDefault();
                                }}>
                                    <div className="w-full bg-gray-300 p-2 rounded-lg">
                                        <div className="flex justify-between items-center">
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                disabled={currentIndex == 0}
                                                style={{
                                                    borderRadius: '1em',
                                                    padding: '0.2em',
                                                }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    paginate(-1)
                                                }}
                                            >
                                                <ChevronLeftIcon />
                                            </Button>

                                            <Typography variant="p" fontWeight={600}>
                                                {currentIndex + 1} of {ticketDetails.length}
                                            </Typography>

                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                disabled={currentIndex == ticketDetails.length - 1}
                                                style={{
                                                    borderRadius: '1em',
                                                    padding: '0.2em',
                                                }}
                                                onClick={(e) => { 
                                                    e.preventDefault();
                                                    paginate(1)
                                                }}
                                            >
                                                <ChevronRightIcon />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

export default BookingDetails

