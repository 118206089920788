// tailwind styled black component for licence plate
import { Typography } from '@mui/material'
import React from 'react'

function PlateCard(props) {
    return (
        <div className="bg-gray-900 text-white p-4 rounded-lg shadow-2xl border-2 border-black flex justify-center items-center">
            <div className='flex flex-col'>
        <div className="text-xs font-bold text-center">
            <Typography variant="p" fontWeight={1000} className='text-gray-300' style={{ fontSize: "1em" }}>BUS PLATE NUMBER</Typography>
        </div>
            {
                props.plate != "TBC" ?
                <div className="flex w-full justify-center">
                    <div className="text-3xl font-bold">{props.plate}</div>
                </div>
                :
                <div className="flex w-full justify-center">
                    <div className="text-md font-bold">To Be Confirmed</div>
                </div>
            }
            </div>
        </div>
    )
}

export default PlateCard