import { Typography } from '@mui/material';

function DepartureIdComponent(props) {
    let { departureId } = props
    return (


        <div>
            <div className="bg-gray-400 text-gray-100 rounded-md px-2 py-0.5 text-center flex items-center mx-2">
                <Typography variant="p" fontWeight={800} style={{
                    fontSize: '0.8em'
                }}>

                    {departureId.slice(-4).toUpperCase() }
                </Typography>
            </div>
        </div>

    )
}

export default DepartureIdComponent