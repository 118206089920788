import { useState, useEffect } from "react"
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { getTickets } from '../networking/utils';
import Loading from './Loading';
import moment from 'moment';
import axios from 'axios';
import { motion } from "framer-motion";
import BookingListCard from "./BookingListCard";
import mrtcrowd from "../assets/mrtcrowd.png";

function FAQ(props) {
    const navigate = useNavigate();
    const [token, setToken] = useState(null)

    useEffect(() => {
        props.setUseLocalStorage(true)
        if (props.currentTab >= 0) {
            props.setCurrentTab(props.tabMappingIndex)
        }
    }, [])


    useEffect(() => {
        if (props.token && props.token != '') {
            setToken(props.token)
        }
    }, [props.token])


    let qaPair = [
        ["Is HitchVibe licensed to sell tickets? ", "HitchVibe is licensed by LTA to operate bus services for the routes we serve."],
        ["What time does the bus leave?", "Departure timings are not fixed as it is highly dependent on the time the concert ends. Typically, the first bus leaves about 45 mins after the concert ends. Updates will be sent regarding your booking's departure time through the Telegram bot."],
        ["What are the payment methods?", "We accept PayNow as our mode of payment."],
        ["Are refunds allowed?", "Refunds are not allowed for change of mind or no-show (including due to weather). However, we may review it on a case-by-case basis for exceptional reasons (e.g. service cancellations on our end or emergencies)."],
        ["What happens if I miss the bus?", "Customers are fully responsible for showing up as we provide ample time, and will send out multiple departure update notifications prior to leaving. As a result, there will be no refunds for no-shows."],
        ["What happens if it rains?", "We strongly recommend packing a poncho for such scenarios. If it rains, we may delay the departure of the bus slightly to give passengers a little more time to get to the pickup point. Unfortunately, if you can't make it to the pickup point by the departure time, we will not be able to refund your ticket as it constitutes a no-show."],
        ["Will I be updated when the bus is leaving?", "Yes, when you book for a particular time slot, you will be updated on when you can board the bus, last call and when the bus has left."],
        ["What happens if the concert ends late?", "We will wait for a reasonable amount of time and most passengers to board the bus before leaving. We will also send out updates on the bus departure time."],
        ["How do I make a booking?", "Booking is done via this website, accessible through our Telegram bot. Click on the 'Book a HitchVibe' tab to get started!"], 
        ["Will I get a confirmation after booking?", "Definitely! Keep a look out for a confirmation message from the bot! Alternatively, you can also access your bookings and tickets under the 'My Bookings' tab on this site."],
        ["Where are the pickup and dropoff points?", "Pickup point is a 10 min walk from Stadium (at 38 Jalan Benaan Kapal). A walking guide will is provided on the bot. Drop off points include Sembawang, Sengkang, Boon Lay and Choa Chu Kang. Also, fret not about getting lost! We will provide all HitchVibers with a PDF on how to get to the pickup point so you can download it beforehand and make it there swiftly!"],
        ["Am I allowed to do multiple bookings?", "Yes, you are allowed to do that! Convenience is one of our mottos and hence, if you and your fellow concert goer intent to book different locations for drop off, you are allowed to do so! Booking different slot timings are also allowed so do not be constrained thinking you can only book one specific location and one specific slot!"],
        ["Is food and refreshment available?", "Do not worry about the lack of refreshments provided! HitchVibe promises that we put our best foot forward to ensure you are well hydrated and have a soothing throat after the concert! We will be providing a bottle of water and a packet of soothing Himalayan Sea Salt sweets for all valued HitchVibers!"],
    ]

    return (
        <div>

            <div className="w-screen p-4">
                <div className="flex flex-col p-4">
                    <div className="mb-2">
                        <Typography variant="h5" fontWeight={800}>
                            FAQ
                        </Typography>
                    </div>
                    {
                        qaPair.map((pair) => {
                            return (
                                <Card className="mb-4 p-4" raised style={{
                                    borderRadius: '0.7em'
                                }}>
                                    <div className="flex flex-col">
                                        <div className="mb-2">
                                            <Typography variant="h6" fontWeight={600}>
                                                {pair[0]}
                                            </Typography>
                                        </div>
                                        <div className="mb-2">
                                            <Typography variant="body1">
                                                {pair[1]}
                                            </Typography>
                                        </div>
                                    </div>
                                </Card>
                            )
                        })
                    }
                </div>
            </div>

        </div>
    )
}

export default FAQ