import React from 'react'
import { Card, CardContent, Typography, Box, Button, Divider } from '@mui/material';

function TicketListCard(props) {
    return (
        <Card style={{
            borderRadius: '0.7em'
        }} raised={props.raised}>
            <CardContent className='flex justify-between'>
                <div>
                    <div className='flex gap-x-2 items-center'> {/* Add 'items-center' to vertically align items */}
                        <Typography variant="h6" component="div" fontWeight={600}>
                            {props.ticket.reference_id}
                        </Typography>
                        <Box
                            component="span"
                            sx={{
                                display: 'inline-block',
                                p: 0.5,
                                borderRadius: '50%',
                                backgroundColor: props.ticket.status === 'active' ?
                                    props.ticket.checked_in ? 'blue' : '#66FF00'
                                    : 'red',
                                width: 12,
                                height: 12,
                            }}
                        />
                    </div>

                    <Typography variant="body2" color="text.secondary">
                        #{props.ticket.seat_num}
                    </Typography>
                </div>

                <div className='flex flex-col items-end'>

                    <Typography variant={props.hideSensitive ? "h4" : "h3"} component="div" fontWeight={600}>
                        {props.hideSensitive ? "🫣" : props.ticket.security_code}
                    </Typography>
                    {
                        props.hideSensitive &&
                        <Typography variant="body2" color="text.secondary" style={{
                            fontSize: '0.62rem'
                        
                        }}>
                            Verification Code Hidden
                        </Typography>
                    }
                </div>
            </CardContent>
        </Card>
    )
}

export default TicketListCard