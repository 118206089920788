import { useState, useEffect } from "react"
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Input from '@mui/joy/Input';
import Snackbar from '@mui/joy/Snackbar';
import { CircularProgress } from '@mui/material';
import { getAllJourneys, getRouteNames, updatePlateNumber, getStaffIds, assignStaff, removeStaff } from '../networking/utils';
import Loading from './Loading';
import moment from 'moment';
import axios from 'axios';
import { motion } from "framer-motion";
import BookingListCard from "./BookingListCard";
import mrtcrowd from "../assets/mrtcrowd.png";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LinearProgress from '@mui/material/LinearProgress';
import DepartureIdComponent from "./DepartureIdComponent";


function AdminPage(props) {
    const navigate = useNavigate();
    const [token, setToken] = useState(null)
    const [fetching, setFetching] = useState(false)
    const [parsedData, setParsedData] = useState([])
    const [fetchError, setFetchError] = useState(true)
    const [dateAccordionState, setDateAccordionState] = useState([])
    const [routeAccordionState, setRouteAccordionState] = useState([])
    const [allAccordionsExpanded, setAllAccordionsExpanded] = useState(false)
    const [routeMapping, setRouteMapping] = useState({})
    const[selectedBusJourneyForPlateUpdate, setSelectedBusJourneyForPlateUpdate] = useState(null)
    const [updatingPlateNumber, setUpdatingPlateNumber] = useState(false)
    const [staffByDate, setStaffByDate] = useState({})
    const [assigningStaff, setAssigningStaff] = useState(false)
    const [assignStaffCounter, setAssignStaffCounter] = useState(0)
    

    const [staffByDateInput, setStaffByDateInput] = useState({})

    const fetchStaffByDate = async (date) => {
        try {
            const response = await getStaffIds(props.token, date).then((response) => {
                console.log("staff by date:", response)
                let staff = response.staff
                let temp = staffByDate
                temp[date] = staff
                setStaffByDate(temp)
                setAssignStaffCounter(assignStaffCounter + 1)
            }).catch((error) => {
                console.error('error fetching staff by date:', error)
            })
        } catch (error) {
            console.error('error fetching staff by date:', error)
        }
    }

    const assignStaffToDate = async (date, userId) => {
        try {
            setAssigningStaff(true)
            const response = await assignStaff(props.token, date, userId).then((response) => {
                console.log("staff assigned:", response)
                fetchStaffByDate(date)
                setAssigningStaff(false)
            }).catch((error) => {
                console.error('error assigning staff:', error)
                setAssigningStaff(false)
            })
        } catch (error) {
            console.error('error assigning staff:', error)
            setAssigningStaff(false)
        }
    }

    const removeStaffFromDate = async (date, userId) => {
        try {
            setAssigningStaff(true)
            const response = await removeStaff(props.token, date, userId).then((response) => {
                console.log("staff removed:", response)
                fetchStaffByDate(date)
                setAssigningStaff(false)
            }).catch((error) => {
                console.error('error removing staff:', error)
                setAssigningStaff(false)
            })
        } catch (error) {
            console.error('error removing staff:', error)
            setAssigningStaff(false)
        }
    }

    const expandAllAccordions = () => {
        const newDateAccordionState = dateAccordionState.map(() => true);
        const newRouteAccordionState = routeAccordionState.map(routeArray =>
            routeArray.map(() => true)
        );

        setDateAccordionState(newDateAccordionState);
        setRouteAccordionState(newRouteAccordionState);
        setAllAccordionsExpanded(true);
    };

    const collapseAllAccordions = () => {
        const newDateAccordionState = dateAccordionState.map(() => false);
        const newRouteAccordionState = routeAccordionState.map(routeArray =>
            routeArray.map(() => false)
        );

        setDateAccordionState(newDateAccordionState);
        setRouteAccordionState(newRouteAccordionState);
        setAllAccordionsExpanded(false);
    };

    const fetchAllBookingsAdmin = async () => {
        try {
            const routeResponse = await getRouteNames(props.token).then((response) => {
                let routeMapping = {}
                response.routes.forEach((route) => {
                    routeMapping[route._id] = route
                })
                console.log("route mapping:", routeMapping)
                setRouteMapping(routeMapping)
            }).catch((error) => {
                console.error('error fetching route names:', error)
                setFetchError(true)
                setFetching(false)
            })
            const response = await getAllJourneys(props.token).then((response) => {
                console.log("all journeys:", response.journeys)
                // put bookings into parsedData where all departures on the same day are grouped together and all departures for the same route are grouped together within the same day, sorted by time
                // sample data:
                //                 broadcast_list
                // : 
                // Array(0)
                // length
                // : 
                // 0
                // [[Prototype]]
                // : 
                // Array(0)
                // bus_contact
                // : 
                // 0
                // bus_plate
                // : 
                // "TBC"
                // capacity_indicator
                // : 
                // 5
                // current_capacity
                // : 
                // 44
                // current_price
                // : 
                // 1300
                // date
                // : 
                // "02-03-2024"
                // is_archived
                // : 
                // false
                // is_departed
                // : 
                // false
                // is_disabled
                // : 
                // false
                // is_fully_booked
                // : 
                // true
                // max_capacity
                // : 
                // 44
                // passenger_chat_ids
                // : 
                // []
                // route_id
                // : 
                // "65d1b1fc96f6d92d7a26177b"
                // show_id
                // : 
                // 1
                // stripe_product_id
                // : 
                // ""
                // timing
                // : 
                // "11.30PM"
                // _id
                // : 
                // "65d1b32896f6d92d7a26177f"
                let data = []
                let journeys = response.journeys
                // data to be in the format:
                // [
                //     {
                //         date: "02-03-2024",
                //         routes: [
                //             {
                //                 route_id: "65d...",
                //                 departures: [
                //                     {
                //                         time: "11.30PM",
                //                         bookings: [
                //                             ...
                //                         ]
                //                     }
                //                 ]
                //             }
                //         ]
                //     }
                // ]

                // loop through all journeys
                for (let i = 0; i < journeys.length; i++) {
                    let journey = journeys[i]
                    let date = journey.date
                    let route_id = journey.route_id
                    let time = journey.timing
                    let booking = journey
                    // check if date exists in data
                    let dateIndex = data.findIndex((item) => item.date == date)
                    if (dateIndex >= 0) {
                        // date exists
                        let routeIndex = data[dateIndex].routes.findIndex((item) => item.route_id == route_id)
                        if (routeIndex >= 0) {
                            // route exists
                            let departureIndex = data[dateIndex].routes[routeIndex].departures.findIndex((item) => item.time == time)
                            if (departureIndex >= 0) {
                                // departure exists
                                console.log('booking', booking)
                                data[dateIndex].routes[routeIndex].departures[departureIndex].bookings.push(booking)
                            } else {
                                // departure does not exist
                                data[dateIndex].routes[routeIndex].departures.push({
                                    time: time,
                                    ...booking
                                })
                            }
                        } else {
                            // route does not exist
                            data[dateIndex].routes.push({
                                route_id: route_id,
                                departures: [
                                    {
                                        time: time,
                                        ...booking
                                    }
                                ]
                            })
                        }
                    } else {
                        // date does not exist
                        data.push({
                            date: date,
                            routes: [
                                {
                                    route_id: route_id,
                                    departures: [
                                        {
                                            time: time,
                                            ...booking
                                        }
                                    ]
                                }
                            ]
                        })
                    }
                }

                console.log("parsed data:", data)

                // create date and route accordion state based on parsed data
                let dateAccordionState = []
                let routeAccordionState = []
                let staffByDateInputState = {}
                for (let i = 0; i < data.length; i++) {
                    fetchStaffByDate(data[i].date)
                    staffByDateInputState[data[i].date] = ''
                    dateAccordionState.push(false)
                    let routes = data[i].routes
                    let routeState = []
                    for (let j = 0; j < routes.length; j++) {
                        routeState.push(false)
                    }
                    routeAccordionState.push(routeState)
                }
                setDateAccordionState(dateAccordionState)
                setRouteAccordionState(routeAccordionState)
                setStaffByDateInput(staffByDateInputState)
                setParsedData(data)
                setFetching(false)
            }).catch((error) => {
                console.error('error fetching all journeys:', error)
                setFetchError(true)
                setFetching(false)
            })
        } catch (error) {
            console.error('error fetching all journeys:', error)
            setFetchError(true)
            setFetching(false)
        }
    }

    useEffect(() => {
        if (token && token != '') {
            setFetching(true)
            setFetchError(false)
            fetchAllBookingsAdmin()
        }
    }, [token])

    useEffect(() => {
        props.setUseLocalStorage(true)
        if (props.currentTab >= 0) {
            props.setCurrentTab(props.tabMappingIndex)
        }
    }, [])


    useEffect(() => {
        if (props.token && props.token != '') {
            setToken(props.token)
        }
    }, [props.token])
    return (
        <div>

            {
                !token || token == '' || fetching &&
                <Loading />
            }

            {
                !fetching && !fetchError && parsedData && parsedData.length == 0 &&
                <Box className="flex flex-col gap-4 text-center mt-12 p-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* 
                    <Lottie
                        animationData={busLoadAnimation}
                        loop={true}
                        style={{
                            width: 200,
                            height: 200

                        }}
                    /> */}
                    <Typography variant="p" className="text-gray-400" fontWeight={800}>
                        No active journeys
                    </Typography>
                </Box>
            }


            {
                fetchError && !fetching &&
                <Box className="flex flex-col gap-4 px-4 py-6">

                    <div style={{
                        padding: '2em',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <h3 style={{
                            textAlign: 'center',
                            marginBottom: '1em',
                            color: '#333333'
                        }}>
                            Error fetching bookings
                        </h3>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setFetching(true)
                                setTimeout(() => {
                                    fetchAllBookingsAdmin()
                                }, 1300)
                            }
                            }
                            style={{
                                maxWidth: '200px',
                            }}
                        >
                            Retry
                        </Button>
                    </div>
                </Box>
            }

            {
                !fetching && !fetchError && parsedData && parsedData.length > 0 &&
                <div className="w-full p-4">
                    <div className="flex w-full items-center justify-around">
                    <Button variant="outlined" className="w-6/12" style={{
                        borderRadius: '0.7em',
                        fontSize: '0.6em'
                    }}
                    onClick={() => {
                        if (allAccordionsExpanded) {
                            collapseAllAccordions()
                        } else {
                            expandAllAccordions()
                        }
                    }}>{
                        allAccordionsExpanded ? "Collapse All" : "Expand All"
                    }</Button>
                    </div>
                    <div className="flex flex-col p-4">
                        {/* <div className="mb-2">
                            <Typography variant="h5" fontWeight={800}>
                                Manage Journeys
                            </Typography>
                        </div> */}
                        {/* <Typography variant="subtitle1" component="div" fontWeight={800}>
                        </Typography> */}
                        {
                            parsedData.map((bookingDate, index) => {
                                return (
                                    <motion.div
                                        key={index}
                                        className="mb-2"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion
                                        expanded={dateAccordionState[index]}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ArrowDropDownIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                onClick={() => {
                                                    let newDateAccordionState = dateAccordionState.map((item, i) => {
                                                        if (i == index) {
                                                            return !item
                                                        } else {
                                                            return item
                                                        }
                                                    })
                                                    setDateAccordionState(newDateAccordionState)
                                                }}
                                            >
                                                <div className="flex flex-col">
                                                    <Typography variant="p" fontWeight={1000}>
                                                        {moment(bookingDate.date, 'DD-MM-YYYY').format('D MMM, ddd')}
                                                    </Typography>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>


                                            <div className="flex" key={assignStaffCounter}>
                                                        <div className="flex flex-col justify-between w-full px-4 py-3 bg-gray-300 rounded-lg">
                                                            <div>
                                                                <Typography variant="p" fontWeight={600} style={{
                                                                    fontSize: '0.9em'
                                                                }}>
                                                                    Staff Assigned
                                                                </Typography>
                                                            </div>
                                                            <div className="flex flex-col gap-2">
                                                                {
                                                                    staffByDate[bookingDate.date] && staffByDate[bookingDate.date].length > 0 ?
                                                                    staffByDate[bookingDate.date].map((staff, index) => {
                                                                        return (
                                                                            <div key={index} className="flex flex-row justify-between items-center bg-gray-600 text-white px-4 py-2 my-2 rounded-lg">
                                                                                <Typography variant="p" fontWeight={700} style={{
                                                                                    fontSize: '0.8em'
                                                                                }}>
                                                                                    {staff}
                                                                                </Typography>
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    color="secondary"
                                                                                    style={{
                                                                                        borderRadius: '0.7em',
                                                                                        fontSize: '0.6em'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        removeStaffFromDate(bookingDate.date, staff)
                                                                                    }}
                                                                                >
                                                                                    Remove
                                                                                </Button>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    )
                                                                    :

                                                                    <Typography variant="p" fontWeight={400}
                                                                    className="py-2"
                                                                    style={{
                                                                        fontSize: '0.8em'
                                                                    }}>
                                                                        No staff assigned
                                                                    </Typography>
                                                                }
                                                            </div>
                                                            <div className="flex">
                                                                <div className="flex flex-row gap-2">
                                                                <input
                                                                    type='text'
                                                                    className={`w-full mr-2 md:w-auto px-4 py-0 my-2 rounded border focus:ring-blue-500 focus:border-transparent`}
                                                                    value={staffByDateInput[bookingDate.date]}
                                                                    placeholder="Chat ID"
                                                                    onChange={(e) => {
                                                                        setStaffByDateInput({
                                                                            ...staffByDateInput,
                                                                            [bookingDate.date]: e.target.value
                                                                        })
                                                                    }}
                                                                />
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className="py-0"
                                                                    style={{
                                                                        borderRadius: '0.7em',
                                                                        fontSize: '0.6em'
                                                                    }}
                                                                    onClick={() => {
                                                                        const userConfirmed = window.confirm('Assign staff to this date? This will grant access to the staff to view, check in, and broadcast to passengers for this date.');
                                                                        if (userConfirmed) {
                                                                            assignStaffToDate(bookingDate.date, staffByDateInput[bookingDate.date])
                                                                        } else {
                                                                            console.log('cancelled')
                                                                        }
                                                                    }}
                                                                >
                                                                    Assign
                                                                </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                {
                                                    bookingDate.routes.map((dateRoute, index) => {
                                                        return (
                                                            <Accordion key={index} expanded={
                                                                routeAccordionState[index]
                                                            }
                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={<ArrowDropDownIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                    onClick={() => {
                                                                        let newRouteAccordionState = routeAccordionState.map((routeArray, i) => {
                                                                            if (i == index) {
                                                                                return routeArray.map(() => !routeArray[0])
                                                                            } else {
                                                                                return routeArray
                                                                            }
                                                                        })
                                                                        setRouteAccordionState(newRouteAccordionState)
                                                                    }}
                                                                >
                                                                    <Typography variant="p" fontWeight={700}>
                                                                        {routeMapping[dateRoute.route_id].dropoff_stop}
                                                                    </Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    {
                                                                        dateRoute.departures.map((departure, index) => {
                                                                            return (
                                                                                <Card key={index}>
                                                                                    <CardContent>

                                                                                        <Box sx={{ width: '100%' }}>
                                                                                            <div className="flex flex-row justify-between">
                                                                                                <div className="flex">
                                                                                                <Typography key={index} variant="p" fontWeight={400}>
                                                                                                    {departure.time} 
                                                                                                </Typography>
                                                                                                <DepartureIdComponent departureId={departure._id} />
                                                                                                <div>
                                                                                                </div></div>
                                                                                                <div className="flex">
                                                                                                    {
                                                                                                        departure.is_departed ?
                                                                                                        <DepartedPill />
                                                                                                        :
                                                                                                        (departure.is_boarding &&
                                                                                                        <BoardingPill />)
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </Box>

                                                                                        <Box sx={{ width: '100%' }}>
                                                                                            <Typography variant="p" className="text-gray-400" style={{
                                                                                                fontSize: '0.8em'
                                                                                            }}>
                                                                                                Capacity ({departure.current_capacity}/{departure.max_capacity})
                                                                                            </Typography>
                                                                                        <LinearProgress 
                                                                                            sx={
                                                                                                {
                                                                                                    borderRadius: 5,
                                                                                                        backgroundColor: 'lightgray',
                                                                                                        '& .MuiLinearProgress-bar': {
                                                                                                            backgroundColor: '#2196F3',
                                                                                                        },
                                                                                                    height: '7px'
                                                                                                }
                                                                                            }
                                                                                        variant="determinate" value={
                                                                                                departure.current_capacity / departure.max_capacity * 100
                                                                                        } />
                                                                                        </Box>

                                                                                        <Box sx={{ width: '100%' }}>
                                                                                            <Typography variant="p" className="text-gray-400" style={{
                                                                                                fontSize: '0.8em'
                                                                                            }}>
                                                                                                Checked In ({departure.checked_in}/{departure.max_capacity})
                                                                                            </Typography>
                                                                                        <LinearProgress 
                                                                                            // background light gray, foreground blue
                                                                                            sx={
                                                                                                {
                                                                                                    borderRadius: 5,
                                                                                                    backgroundColor: 'lightgray',
                                                                                                    '& .MuiLinearProgress-bar': {
                                                                                                        backgroundColor: '#2196F3',
                                                                                                    },
                                                                                                    height: '7px'
                                                                                                }
                                                                                            }
                                                                                        variant="determinate" value={
                                                                                            departure.checked_in / departure.max_capacity * 100
                                                                                        } />
                                                                                        </Box>

                                                                                        <Box sx={{ width: '100%' }}>
                                                                                            <Typography variant="p" className="text-gray-400" style={{
                                                                                                fontSize: '0.8em'
                                                                                            }}>
                                                                                                Bus Plate Number
                                                                                            </Typography>
                                                                                            {/* // 70% input, 30% button */}
                                                                                            <div className="flex flex-row justify-between">
                                                                                                <input
                                                                                                    type='text'
                                                                                                    className={`w-full mr-2 md:w-auto px-4 py-0 my-2 rounded border focus:ring-blue-500 focus:border-transparent`}
                                                                                                    value={departure.bus_plate}
                                                                                                    onChange={(e) => {
                                                                                                        // update bus plate number in state, and current bus journey for plate update
                                                                                                        setSelectedBusJourneyForPlateUpdate(departure._id)
                                                                                                        setParsedData(parsedData.map((date) => {
                                                                                                            return {
                                                                                                                ...date,
                                                                                                                routes: date.routes.map((route) => {
                                                                                                                    return {
                                                                                                                        ...route,
                                                                                                                        departures: route.departures.map((departure) => {
                                                                                                                            if (departure._id == selectedBusJourneyForPlateUpdate) {
                                                                                                                                return {
                                                                                                                                    ...departure,
                                                                                                                                    bus_plate: e.target.value
                                                                                                                                }
                                                                                                                            } else {
                                                                                                                                return departure
                                                                                                                            }
                                                                                                                        })
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                        }))
                                                                                                    }}
                                                                                                    placeholder="Plate Number"
                                                                                                />
                                                                                                { updatingPlateNumber ?
                                                                                                <CircularProgress />
                                                                                                :
                                                                                                <Button
                                                                                                    variant="contained"
                                                                                                    color="primary"
                                                                                                    style={{
                                                                                                        padding: '0.5em',
                                                                                                        width: '30%',
                                                                                                        borderRadius: '0.7em',
                                                                                                        fontSize: '0.6em'
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        setUpdatingPlateNumber(true)
                                                                                                        updatePlateNumber(props.token, departure._id, departure.bus_plate)
                                                                                                        setUpdatingPlateNumber(false)
                                                                                                        alert('Plate number updated')
                                                                                                    }}
                                                                                                >
                                                                                                    Update
                                                                                                </Button>
                                                                                                }
                                                                                                
                                                                                            </div>
                                                                                        </Box>
                                                                                    </CardContent>
                                                                                </Card>
                                                                            )
                                                                        })
                                                                    }
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        )
                                                    })
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    </motion.div>
                                )
                            })
                        }
                    </div>
                </div>
            }

        </div>
    )
}


function BoardingPill() {
    return (

        <div>
            <div className="bg-cyan-500 text-cyan-100 rounded-full px-2 py-0.5 text-center flex items-center mr-1">

                <Typography variant="p" fontWeight={700} style={{
                    fontSize: '0.65em'
                }}>
                    Boarding
                </Typography>
            </div>
        </div>
    )
}


function DepartedPill() {
    return (

        <div>
            <div className="bg-green-500 text-green-100 rounded-full px-2 py-0.5 text-center flex items-center mr-1">
                <Typography variant="p" fontWeight={700} style={{
                    fontSize: '0.65em'
                }}>
                    Departed
                </Typography>
            </div>
        </div>
    )
}


export default AdminPage
