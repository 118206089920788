import React from 'react'
import Lottie from "lottie-react";
import busLoadAnimation from "../lottie-assets/bus-load.json";

function Loading(props) {
    let loadText = props.loadText;
    if (loadText === undefined) {
        loadText = "Loading.."
    }
  return (
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <Lottie
            animationData={busLoadAnimation}
            loop={true}
            style={{
                width: 200,
                height: 200

            }}
        />
        <h3 style={{
            // dark grey
        textAlign: 'center',
        marginBottom: '1em',
            color: '#333333'
        }}>{loadText}</h3>

    </div>
  )
}

export default Loading