import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { login } from '../networking/utils';
// import Lottie from "lottie-react";
// import busLoadAnimation from "../lottie-assets/bus-load.json";
import Loading from './Loading';
import Button from '@mui/material/Button';
function Login(props) {
    const { REACT_APP_ENVIRONMENT, REACT_APP_BOT_NAME } = process.env;
    const navigate = useNavigate()
    const { token } = useParams()
    const [responseStatusCode, setResponseStatusCode] = useState(0)
    const post_login = async (token) => {
        login(token)
            .then(({data, statusCode}) => {
                // console.log("auth response in login:", statusCode)
                if (statusCode === 200) {
                    // console.log("auth validated")
                    props.setValidatedToken(token)
                    props.setRoles(data.roles)
                    setResponseStatusCode(statusCode)
                    // redirect to / after 500 ms
                    setTimeout(() => {
                        navigate('/')
                    }, 500)
                } else {
                    // console.log('auth failed')
                    setResponseStatusCode(-1)
                    props.setCurrentTab(-1)
                }
            })
            .catch(error => {
                // console.error('error during login:', error);
                setResponseStatusCode(-1)
                props.setCurrentTab(-1)
                // alert('Error during login')
            });
    }

    useEffect(() => {
        if (token) {
            post_login(token)
        }
    }, [])
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                {responseStatusCode == 0 &&
                    <Loading />}

                {
                    responseStatusCode == -1 &&
                    <div style={{
                        padding: '2em',
                        width: '50%',
                        // align center
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',

                    }}>
                    <h3 style={{
                        // center text
                        textAlign: 'center',
                        marginBottom: '1em',
                        color: '#333333'
                    }}>Your login link has expired.
                        Please generate a new one through the bot.</h3>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                // open tg resolve link with start param
                                // window.open('https://t.me/@ilt_local_1_bot?start', '_blank')
                                window.open('tg://resolve?domain=' + REACT_APP_BOT_NAME + '&start=login', '_blank')
                            }}
                            style={{
                                maxWidth: '200px',
                            }}
                        >
                            Open In Telegram
                        </Button>
                    </div>
                }

                {
                    responseStatusCode == 200 &&
                    <div style={{
                        padding: '2em',
                        width: '50%',
                        // align center
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <h3 style={{
                            // dark grey
                        textAlign: 'center',
                        marginBottom: '1em',
                            color: '#333333'
                        }}>✅ Verified</h3>
                    </div>
                }

                {
                    (responseStatusCode == 401 || responseStatusCode == 400) &&
                    <div style={{
                        padding: '2em',
                        width: '50%',
                        // align center
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <h3 style={{
                            // dark grey
                        textAlign: 'center',
                        marginBottom: '1em',
                            color: '#333333'
                        }}>Login access link has expired or is not valid.</h3>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                // open tg resolve link with start param
                                // window.open('https://t.me/@ilt_local_1_bot?start', '_blank')
                                window.open('tg://resolve?domain=' + REACT_APP_BOT_NAME + '&start=login', '_blank')
                            }}
                            style={{
                                maxWidth: '200px',
                            }}
                        >
                            Retry Login
                        </Button>
                    </div>
                }
            </div>
        </>
    )
}

export default Login