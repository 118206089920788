import React from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel'; // Import cancel icon
import { useNavigate } from 'react-router-dom';

function Cancelled() {
    const navigate = useNavigate(); // Hook for navigation

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <Card className='m-4 px-8 py-2' style={{
                borderRadius: '0.7em'
            }} raised>
                <CardContent>
                    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                        <CancelIcon color="error" sx={{ fontSize: 60 }} /> {/* Cancel Icon */}
                        <Typography variant="h5" component="div">
                            Payment Failed
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            We're sorry, your payment did not go through.
                        </Typography>
                        <Button variant="contained" color="primary" onClick={() => navigate('/')}>
                            Return Home
                        </Button>
                        {/* Optional: Button to retry or perform another action */}
                        {/* <Button variant="outlined" onClick={() => navigate('/retry')}>
                            Try Again
                        </Button> */}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

export default Cancelled;