import { useState, useEffect } from "react"
import { Card, CardContent, Typography, Box, Button, InputLabel, FormControl, Checkbox, FormControlLabel, Divider } from '@mui/material';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Input from '@mui/joy/Input';
import Snackbar from '@mui/joy/Snackbar';
import { CircularProgress } from '@mui/material';
import { getJourneysForStaff, getOneJourneyForStaff, getRouteNames, getTicketsForJourney, updateTicketStatus, batchUpdateTicketStatus, triggerIsBoarding, triggerIsDeparted, sendBroadcast } from '../networking/utils';
import Loading from './Loading';
import moment from 'moment';
import axios from 'axios';
import { motion } from "framer-motion";
import BookingListCard from "./BookingListCard";
import mrtcrowd from "../assets/mrtcrowd.png";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LinearProgress from '@mui/material/LinearProgress';
import DepartureIdComponent from "./DepartureIdComponent";
import PlateCard from "./PlateCard";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function StaffDetailsPage(props) {
    let query = useQuery();
    const jid = query.get("jid");
    const navigate = useNavigate();
    const [token, setToken] = useState(null)
    const [fetching, setFetching] = useState(false)
    const [parsedData, setParsedData] = useState([])
    const [fetchError, setFetchError] = useState(true)
    const [staffByDateInput, setStaffByDateInput] = useState({})
    const [searchQuery, setSearchQuery] = useState('')
    const [routeMapping, setRouteMapping] = useState({})
    const [journey, setJourney] = useState(null)
    const [loading, setLoading] = useState(false)
    const [templateValue, setTemplateValue] = useState(0)
    const [sendToAll, setSendToAll] = useState(false)
    const [broadcastMessage, setBroadcastMessage] = useState('')
    let templateMapping = {
        0: "Custom Message",
        1: "We hope you've had a marvellous time at The Eras Tour!\n\nYour HitchVibe journey will be departing at 11.15pm. Please arrive at the pickup point 15 minutes early (by 11pm) to avoid delays.\n\nFind our staff at the coffee shop by the pickup point with the blue lightsticks when you've arrived to collect your refreshments and get checked-in for your ride home!",
        2: "Your HitchVibe journey is departing in 20 minutes. Please make your way to the pickup point now, thank you!",
        3: "Your HitchVibe journey is departing in 10 minutes. Please make your way to the pickup point now, thank you!",
        4: "🚨 This is the last call for your HitchVibe journey. Please make your way to the pickup point now, or you might risk missing your journey. Thank you!"
    }

    const sendBroadcastMsg = async (token, journeyId, message, sendToAll) => {
        try {
            setLoading(true)
            setSendToAll(false)
            let payload = templateValue == 0 ? broadcastMessage : templateMapping[templateValue]
            const response = await sendBroadcast(token, journeyId, payload, !sendToAll).then((response) => {
                console.log("broadcast sent:", response)
                setLoading(false)
            }).catch((error) => {
                console.error('error sending broadcast:', error)
                setLoading(false)
            })
        } catch (error) {
            console.error('error sending broadcast:', error)
            setLoading(false)
        }
    }

    const fetchStaffJourney = async () => {
        try {
            const routeResponse = await getRouteNames(props.token).then((response) => {
                let routeMapping = {}
                response.routes.forEach((route) => {
                    routeMapping[route._id] = route
                })
                console.log("route mapping:", routeMapping)
                setRouteMapping(routeMapping)
            }).catch((error) => {
                console.error('error fetching route names:', error)
                setFetchError(true)
                setFetching(false)
            })
            const response = await getOneJourneyForStaff(props.token, jid).then((response) => {
                let journey = response.journey
                console.log("journey:", journey)
                setJourney(journey)
                setFetchError(false)
                setFetching(false)
            }).catch((error) => {
                console.error('error fetching all journeys:', error)
                setFetchError(true)
                setFetching(false)
            })
        } catch (error) {
            console.error('error fetching all journeys:', error)
            setFetchError(true)
            setFetching(false)
        }
    }

    useEffect(() => {
        if (token && token != '') {
            setFetching(true)
            setFetchError(false)
            fetchStaffJourney()
        }
    }, [token])

    useEffect(() => {
        props.setUseLocalStorage(true)
        if (props.currentTab >= 0) {
            props.setCurrentTab(props.tabMappingIndex)
        }
    }, [])


    useEffect(() => {
        if (props.token && props.token != '') {
            setToken(props.token)
        }
    }, [props.token])
    return (
        <div>

            {
                !token || token == '' || fetching &&
                <Loading />
            }

            {
                loading &&

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <CircularProgress />
                </div>
            }

            {
                !fetching && !fetchError && !journey &&
                <Box className="flex flex-col gap-4 text-center mt-12 p-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* 
                    <Lottie
                        animationData={busLoadAnimation}
                        loop={true}
                        style={{
                            width: 200,
                            height: 200

                        }}
                    /> */}
                    <Typography variant="p" className="text-gray-400" fontWeight={800}>
                        Invalid Journey ID
                    </Typography>
                </Box>
            }


            {
                fetchError && !fetching &&
                <Box className="flex flex-col gap-4 px-4 py-6">

                    <div style={{
                        padding: '2em',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <h3 style={{
                            textAlign: 'center',
                            marginBottom: '1em',
                            color: '#333333'
                        }}>
                            Error fetching bookings
                        </h3>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setFetching(true)
                                setTimeout(() => {
                                    fetchStaffJourney()
                                }, 1300)
                            }
                            }
                            style={{
                                maxWidth: '200px',
                            }}
                        >
                            Retry
                        </Button>
                    </div>
                </Box>
            }

            {
                !fetching && !fetchError && journey && !loading &&
                <div className="w-screen p-4">
                    <div
                        className="cursor-pointer px-4 py-2 rounded-lg bg-gray-100 text-gray-500 text-xs text-center mb-3"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            navigate("/staff")
                        }}>
                        <Typography variant="subtitle1" component="div" fontWeight={800} fontSize={11}>
                            « Select Another Ride
                        </Typography>
                    </div>
                    <Card className="w-full p-4">
                        <CardContent>
                            <div className="flex flex-col">
                                <Typography variant="h6" fontWeight={800} className="mb-4">
                                    Ride Details
                                </Typography>
                                {/* <div className="w-full">
                                    <input type="text" placeholder="Search 4-digit ID" className="w-full p-2 rounded-lg border-2 border-gray-300 mb-3" onChange={(e) => {
                                        setSearchQuery(e.target.value)
                                    }
                                    } />
                                </div> */}
                            </div>
                            {journey &&
                                <div className="flex flex-col gap-4">
                                    <Card className="my-1 cursor-pointer" raised style={{
                                        borderRadius: '0.7em'
                                    }}>
                                        {/* show, time, bus_plate, last 4 digits of _Id */}
                                        <CardContent>
                                            <div className="flex justify-between">
                                                <div className="flex flex-col">
                                                    <Typography variant="p" fontWeight={800} className="mb-2">
                                                        {routeMapping[journey.route_id] && routeMapping[journey.route_id].dropoff_stop}
                                                    </Typography>
                                                    <Typography variant="p" fontWeight={400} className="mb-2">
                                                        {journey.date}
                                                    </Typography>
                                                    <Typography variant="p" fontWeight={400} className="mb-2">
                                                        {journey.timing}
                                                    </Typography>
                                                </div>
                                                <div className="flex flex-col">
                                                    <div className="bg-gray-900 text-white p-1 rounded-lg shadow-2xl border-2 mb-2 border-black flex justify-center items-center">
                                                        <Typography variant="p" fontWeight={800} className="mb-2">
                                                            {journey.bus_plate}
                                                        </Typography>
                                                    </div>
                                                    <DepartureIdComponent departureId={journey._id} />
                                                </div>
                                            </div>

                                            <Box sx={{ width: '100%' }}>
                                                <Typography variant="p" className="text-gray-400" style={{
                                                    fontSize: '0.8em'
                                                }}>
                                                    Capacity ({journey.current_capacity}/{journey.max_capacity}) - {Math.floor(journey.current_capacity / journey.max_capacity * 100)}%
                                                </Typography>
                                                <LinearProgress
                                                    sx={
                                                        {
                                                            borderRadius: 5,
                                                            backgroundColor: 'lightgray',
                                                            '& .MuiLinearProgress-bar': {
                                                                backgroundColor: '#2196F3',
                                                            },
                                                            height: '7px'
                                                        }
                                                    }
                                                    variant="determinate" value={
                                                        journey.current_capacity / journey.max_capacity * 100
                                                    } />
                                            </Box>

                                            <Box sx={{ width: '100%' }}>
                                                <Typography variant="p" className="text-gray-400" style={{
                                                    fontSize: '0.8em'
                                                }}>
                                                    Checked In ({journey.checked_in}/{journey.max_capacity}) - {Math.floor(journey.checked_in / journey.max_capacity * 100)}%
                                                </Typography>
                                                <LinearProgress
                                                    // background light gray, foreground blue
                                                    sx={
                                                        {
                                                            borderRadius: 5,
                                                            backgroundColor: 'lightgray',
                                                            '& .MuiLinearProgress-bar': {
                                                                backgroundColor: '#2196F3',
                                                            },
                                                            height: '7px'
                                                        }
                                                    }
                                                    variant="determinate" value={
                                                        journey.checked_in / journey.max_capacity * 100
                                                    } />
                                            </Box>



                                            <Box sx={{ width: '100%' }}>
                                                <Typography variant="p" className="text-gray-400" style={{
                                                    fontSize: '0.8em'
                                                }}>
                                                    {/* calc perc in whole num */}
                                                    Checked In / Capacity  ({journey.checked_in}/{journey.current_capacity}) - {Math.floor(journey.checked_in / journey.current_capacity * 100)}%
                                                </Typography>
                                                <LinearProgress
                                                    // background light gray, foreground blue
                                                    sx={
                                                        {
                                                            borderRadius: 5,
                                                            backgroundColor: 'lightgray',
                                                            '& .MuiLinearProgress-bar': {
                                                                backgroundColor: '#2196F3',
                                                            },
                                                            height: '7px'
                                                        }
                                                    }
                                                    variant="determinate" value={
                                                        journey.checked_in / journey.current_capacity * 100
                                                    } />
                                            </Box>

                                            {
                                                journey.is_departed &&
                                                <div className="flex flex-col gap-4 mt-4 text-center">
                                                    <DepartedPill />
                                                </div>
                                            }
                                            {
                                                journey.is_boarding && !journey.is_departed &&
                                                <div className="flex flex-col gap-4 mt-4 text-center">
                                                    <BoardingPill />
                                                </div>
                                            }
                                        </CardContent>
                                    </Card>

                                    <Divider />
                                    <Typography variant="h6" fontWeight={800} className="mt-4">
                                        Actions
                                    </Typography>
                                    {
                                        journey.is_boarding &&
                                        <Box className="flex flex-col" onClick={() => {
                                            navigate(`/staff-checkin?jid=${journey._id}`)
                                        }}>
                                            <div className="w-full bg-green-300 text-green-700 text-center justify-around rounded-xl flex cursor-pointer py-4 mb-4">
                                                <Typography variant="p" fontWeight={800} className="p-4">
                                                    Tap here to check-in passengers
                                                </Typography>
                                            </div>
                                        </Box>
                                    }
                                </div>
                            }

                            <Divider />

                            <Box className="flex flex-col gap-4 py-8">
                                <FormControl>
                                    <InputLabel>Broadcast Message</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={templateValue}
                                        onChange={(e) => {
                                            setTemplateValue(e.target.value);
                                        }}
                                        label="Select a broadcast template"
                                        placeholder="Select a broadcast template"
                                    >
                                        {/* <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem> */}
                                        <MenuItem value={0}>Custom Message</MenuItem>
                                        <MenuItem value={1}>Concert Ended - 40 mins</MenuItem>
                                        <MenuItem value={2}>20 Minutes Till Departure</MenuItem>
                                        <MenuItem value={3}>10 Minutes Till Departure</MenuItem>
                                        <MenuItem value={4}>Last Call</MenuItem>
                                    </Select></FormControl>


                                {
                                    templateValue == 0 &&
                                    <textarea
                                        className="p-4 rounded-lg border-2 border-gray-300"
                                        placeholder="Type your custom message here"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        value={broadcastMessage}
                                        onChange={(e) => {
                                            setBroadcastMessage(e.target.value)
                                        }}
                                    />
                                }

                                <div className="flex flex-col gap-4 bg-blue-100 rounded-lg">
                                    <Typography variant="p" fontWeight={800} className="px-4 pt-2">
                                        [HitchVibe Alert from Staff]
                                    </Typography>
                                    <Typography variant="p" fontWeight={500} className="p-4">
                                        {templateValue == 0 ? broadcastMessage : templateMapping[templateValue]}
                                    </Typography>
                                </div>


                                <div className="flex justify-between items-center w-full">
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="checkedSendToUnboarded"
                                                value={sendToAll}
                                                selected={sendToAll}
                                                onClick={() => {
                                                    setSendToAll(!sendToAll)
                                                }} />
                                        }
                                        label="Send to all passengers"
                                    />
                                    <Button variant="contained" color="primary"
                                        onClick={() => {
                                            const userConfirmed = window.confirm('Confirm sending broadcast?');
                                            if (!userConfirmed) {
                                                return
                                            }
                                            sendBroadcastMsg(token, journey._id, broadcastMessage, sendToAll)
                                        }}>
                                        {
                                            sendToAll ? "Send to All" : "Send to Unboarded"
                                        }
                                    </Button>
                                </div>


                                <Divider />
                                {
                                    !journey.is_departed &&
                                    <Box className="flex flex-col gap-4 mt-3">
                                        <Button
                                            disabled={journey.is_departed || journey.is_boarding || loading}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {

                                                const userConfirmed = window.confirm('Confirm trigger boarding? This will send the following message to all passengers: Your HitchVibe ride is almost here, to ___. Our staff on the ground will begin boarding shortly, so please make your way to the pickup point at the coffee shop along Jln Benaan Kepal now.');

                                                if (userConfirmed) {
                                                    setLoading(true)
                                                    triggerIsBoarding(token, journey._id).then((response) => {
                                                        console.log("triggered is boarding:", response)
                                                        setLoading(false)
                                                        fetchStaffJourney()
                                                    }).catch((error) => {
                                                        console.error('error triggering is boarding:', error)
                                                        setLoading(false)
                                                    }
                                                    )
                                                }
                                            }
                                            }
                                        >{
                                                journey.is_boarding ?
                                                    "Now Boarding" : "Mark as Boarding"
                                            }</Button>
                                    </Box>
                                }
                                {
                                    journey.is_boarding &&
                                    <Box className="flex flex-col gap-4 mt-3">
                                        <Button
                                            disabled={(journey.is_departed && journey.is_boarding) || loading}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                const userConfirmed = window.confirm('Confirm trigger departure? This will send the following message to all passengers: Final Departure Alert - Your HitchVibe ride to ____ is departing now.');

                                                if (userConfirmed) {
                                                    setLoading(true)
                                                    triggerIsDeparted(token, journey._id).then((response) => {
                                                        console.log("triggered is departed:", response)
                                                        setLoading(false)
                                                        fetchStaffJourney()
                                                    }).catch((error) => {
                                                        console.error('error triggering is departed:', error)
                                                        setLoading(false)
                                                    }
                                                    )
                                                }
                                            }
                                            }
                                        >{
                                                journey.is_departed ?
                                                    "Marked as Departed" : "Mark as Departed"
                                            }</Button>
                                    </Box>
                                }
                            </Box>
                        </CardContent>
                    </Card>
                </div>
            }

        </div>
    )
}


function BoardingPill() {
    return (

        <div>
            <div className="bg-cyan-500 text-cyan-100 rounded-full px-2 py-0.5 text-center flex justify-around mr-1">

                <Typography variant="p" fontWeight={700} style={{
                    fontSize: '0.65em'
                }}>
                    Boarding
                </Typography>
            </div>
        </div>
    )
}


function DepartedPill() {
    return (

        <div>
            <div className="bg-green-500 text-green-100 rounded-full px-2 py-0.5 text-center flex justify-around mr-1">
                <Typography variant="p" fontWeight={700} style={{
                    fontSize: '0.65em',
                }}>
                    Departed
                </Typography>
            </div>
        </div>
    )
}


export default StaffDetailsPage
