import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import { Card, CardContent, Typography, Box, Button, Divider } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function BookingListCard(props) {
    const navigate = useNavigate();
    const Button = ({ children, size }) => {
        const baseStyles = 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded';
        const sizeStyles = size === 'sm' ? 'text-sm py-1 px-2' : 'text-md py-2 px-4';

        return (
            <button className={`${baseStyles} ${sizeStyles}`}>
                {children}
            </button>
        );
    };
    return (
        <motion.div 
        whileHover={{ scale: 1.03 }}
        whileTap={{ scale: 0.97 }}
        onClick={() => navigate(props.forwardUrl)}
        >
        <Card className='w-full my-4' style={{
            borderRadius: '0.7em'
        }} raised>
            <CardContent style={{
                // padding x: 8, padding y : 12
                padding: '1em 0.2em'
            }}>
                <div className="px-4 flex justify-between items-center space-x-1">
                    <div className='flex space-x-2'>
                    <div className="text-4xl flex flex-col items-center space-x-1 pr-2">
                        <span className="font-semibold">{props.tickets}</span>
                        <span className="text-sm font-medium tracking-wide">ticket{(props.tickets == 1 ? "" : "s")}</span>
                    </div>
                    <div className="border-l border-gray-300" />
                    <div className="grid gap-1 text-sm leading-none pl-2">
                        <p className="truncate">
                            <Typography variant="p" fontWeight={800}>
                                {props.destination}
                            </Typography>
                        </p>
                        <p>
                            <Typography variant="p" fontWeight={600}>
                                {props.date}
                            </Typography>
                        </p>
                        <p>
                            <Typography variant="p" fontWeight={600}>
                                {props.time}
                            </Typography>
                        </p>
                    </div>
                    </div>

                    <div>
                        <ChevronRightIcon className="text-2xl" />
                    </div>
                    
                </div>
            </CardContent>
        </Card>
        </motion.div>
    )
}

export default BookingListCard