import React from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Import success icon
import { useNavigate } from 'react-router-dom';

function Success() {
    const navigate = useNavigate(); // Hook for navigation

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <Card className='mx-4 px-8 py-2' style={{
                borderRadius: '0.7em'
            }} raised> {/* Adjust width as needed */}
                <CardContent>
                    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                        <CheckCircleOutlineIcon color="success" sx={{ fontSize: 60 }} /> {/* Success Icon */}
                        <Typography variant="h5" component="div">
                            Success
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            We've received your payment!
                        </Typography>
                        <Button variant="contained" onClick={() => navigate('/bookings')}>
                            View Bookings
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

export default Success;
