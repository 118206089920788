import { useState, useEffect } from "react"
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { getTickets } from '../networking/utils';
import Loading from './Loading';
import moment from 'moment';
import axios from 'axios';
import { motion } from "framer-motion";
import BookingListCard from "./BookingListCard";
import mrtcrowd from "../assets/mrtcrowd.png";

function MyBookings(props) {
    const navigate = useNavigate();
    const [token, setToken] = useState(null)
    const [fetchingActiveBookings, setFetchingActiveBookings] = useState(false)
    const [activeBookings, setActiveBookings] = useState([])
    const [fetchError, setFetchError] = useState(true)

    const fetchActiveBookings = async () => {
        try {
            const response = await getTickets(props.token).then((response) => {
                // console.log("active bookings:", response)
                setActiveBookings(response)
                setFetchingActiveBookings(false)
            }).catch((error) => {
                console.error('error fetching active bookings:', error)
                setFetchError(true)
                setFetchingActiveBookings(false)
            })
        } catch (error) {
            console.error('error fetching active bookings:', error)
            setFetchError(true)
            setFetchingActiveBookings(false)
        }
    }

    useEffect(() => {
        if (token && token != '') {
            setFetchingActiveBookings(true)
            setFetchError(false)
            fetchActiveBookings()
        }
    }, [token])

    useEffect(() => {
        props.setUseLocalStorage(true)
        if (props.currentTab >= 0) {
            props.setCurrentTab(1)
        }
    }, [])


    useEffect(() => {
        if (props.token && props.token != '') {
            setToken(props.token)
        }
    }, [props.token])
    return (
        <div>

            {
                !token || token == '' || fetchingActiveBookings &&
                <Loading />
            }

            {
                // empty bookings
                !fetchingActiveBookings && !fetchError && activeBookings && activeBookings.length == 0 &&
                <Box className="flex flex-col gap-4 text-center mt-12 p-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
{/* 
                    <Lottie
                        animationData={busLoadAnimation}
                        loop={true}
                        style={{
                            width: 200,
                            height: 200

                        }}
                    /> */}
                    <Typography variant="p" className="text-gray-400" fontWeight={800}>
                        Uh oh, you don't have any bookings.
                    </Typography>
                    <img src={mrtcrowd} alt="mrtcrowd" style={{ width: '80%%', height:'auto', maxHeight: '60vh'}} className="rounded-lg" />
                    <Typography variant="p" className="text-gray-400" fontWeight={500}>
                        Ready to snag a comfy ride home?
                    </Typography>
                    <Button
                        style={{
                            borderRadius: '0.7em'
                        }}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            navigate(`/`)
                        }
                        }
                    >
                        BOOK A HITCHVIBE
                    </Button>
                </Box>
            }


            {
                fetchError && !fetchingActiveBookings &&
                <Box className="flex flex-col gap-4 px-4 py-6">

                    <div style={{
                        padding: '2em',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <h3 style={{
                            textAlign: 'center',
                            marginBottom: '1em',
                            color: '#333333'
                        }}>
                            Error fetching bookings
                        </h3>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setFetchingActiveBookings(true)
                                setTimeout(() => {
                                    fetchActiveBookings()
                                }, 1300)
                            }
                            }
                            style={{
                                maxWidth: '200px',
                            }}
                        >
                            Retry
                        </Button>
                    </div>
                </Box>
            }

            {
                !fetchingActiveBookings && !fetchError && activeBookings && activeBookings.length > 0 &&
                <div className="w-screen p-4">
                    <div className="flex flex-col p-4">
                        <div className="mb-2">
                            <Typography variant="h5" fontWeight={800}>
                                Active Bookings
                            </Typography>
                        </div>
                        {/* <Typography variant="subtitle1" component="div" fontWeight={800}>
                        </Typography> */}
                        {
                            activeBookings.map((booking, index) => {
                                return (
                                    <motion.div
                                        key={index}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                    >
                                        <BookingListCard
                                            forwardUrl={`/booking-details?jid=${booking.journey_id}&rid=${booking.reference_id}`}
                                            route={booking.route}
                                            destination={booking.destination}
                                            date={moment(booking.date).format('D MMMM')}
                                            time={booking.time}
                                            tickets={booking.num_seats}
                                        />
                                    </motion.div>
                                )
                            })
                        }
                    </div>
                </div>
            }

        </div>
    )
}

export default MyBookings