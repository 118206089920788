
import axios from 'axios';
const { REACT_APP_ENVIRONMENT, REACT_APP_BOT_NAME, REACT_APP_BACKEND_URI } = process.env;

// networking functions to fetch data from backend, await all promises and return only the data

//GET - events, with valid token
export async function getEvents(token) {
    console.log(REACT_APP_BACKEND_URI, token)
    const response = await axios.get(`${REACT_APP_BACKEND_URI}api/events`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}


//GET - route summaries with token validation in auth header, for a given event and date
export async function getRouteSummaries(token, event, date) {
    const response = await axios.get(`${REACT_APP_BACKEND_URI}api/route-summary`, {
        params: {
            event_id: event,
            event_date: date
        },
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}


// [ADMIN/STAFF] GET - get route names
export async function getRouteNames(token) {
    const response = await axios.get(`${REACT_APP_BACKEND_URI}api/route-names`, {
        headers: {
            'Authorization' : `Bearer ${token}`
        }
    });
    return response.data;
}

// [ADMIN] POST - update plate number
export async function updatePlateNumber(token, journeyId, plateNumber) {
    const response = await axios.post(`${REACT_APP_BACKEND_URI}api/update-plate-number`, {
        journey_id: journeyId,
        plate_number: plateNumber
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

// [ADMIN] - GET - get staff user ids for a given date
export async function getStaffIds(token, date) {
    const response = await axios.get(`${REACT_APP_BACKEND_URI}api/staff-user-ids`, {
        params: {
            date: date
        },
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

// [ADMIN] - POST - assign staff to a date
export async function assignStaff(token, date, userId) {
    const response = await axios.post(`${REACT_APP_BACKEND_URI}api/assign-staff`, {
        date: date,
        user_id: userId
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

// [ADMIN] - POST - remove staff from a date
export async function removeStaff(token, date, userId) {
    const response = await axios.post(`${REACT_APP_BACKEND_URI}api/remove-staff`, {
        date: date,
        user_id: userId
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

// [STAFF] - GET - get all journeys for a staff given their token, verify that staff has permission to view (based on date)
export async function getJourneysForStaff(token) {
    const response = await axios.get(`${REACT_APP_BACKEND_URI}api/journeys-for-staff`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

// [STAFF] - GET - get details for a given journey, verify that staff has permission to view (based on date)
export async function getOneJourneyForStaff(token, journeyId) {
    const response = await axios.get(`${REACT_APP_BACKEND_URI}api/one-journey-for-staff`, {
        params: {
            journey_id: journeyId
        },
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

// [STAFF] - GET - get all tickets for a given journey, verify that staff has permission to view (based on date)
export async function getTicketsForJourney(token, journeyId) {
    const response = await axios.get(`${REACT_APP_BACKEND_URI}api/tickets-for-journey`, {
        params: {
            journey_id: journeyId
        },
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

// [STAFF] - PATCH - update ticket status
export async function updateTicketStatus(token, ticketId, status) {
    const response = await axios.post(`${REACT_APP_BACKEND_URI}api/update-ticket-status`, {
        ticket_id: ticketId,
        status: status
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

// [STAFF] - POST - batch update ticket status based on reference id
export async function batchUpdateTicketStatus(token, referenceId, status) {
    const response = await axios.post(`${REACT_APP_BACKEND_URI}api/batch-update-ticket-status`, {
        reference_id: referenceId,
        status: status
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

// [STAFF] - POST - trigger is boarding
export async function triggerIsBoarding(token, journeyId) {
    const response = await axios.post(`${REACT_APP_BACKEND_URI}api/trigger-is-boarding`, {
        journey_id: journeyId
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

// [STAFF] - POST - trigger is departed
export async function triggerIsDeparted(token, journeyId) {
    const response = await axios.post(`${REACT_APP_BACKEND_URI}api/trigger-is-departed`, {
        journey_id: journeyId
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

// [STAFF] - POST - send broadcast
export async function sendBroadcast(token, journeyId, message, forNonBoarded) {
    const response = await axios.post(`${REACT_APP_BACKEND_URI}api/send-broadcast`, {
        journey_id: journeyId,
        message: message,
        for_non_boarded: forNonBoarded
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}


//GET - available seats for a given journey, with token validation in auth header
export async function getAvailableSeats(token, journeyId) {
    const response = await axios.get(`${REACT_APP_BACKEND_URI}api/available-seats`, {
        params: {
            journey_id: journeyId
        },
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}


// POST - create booking with token validation in auth header
export async function createBooking(token, journeyId, numSeats) {
    const response = await axios.post(`${REACT_APP_BACKEND_URI}api/create-booking`, {
        journey_id: journeyId,
        num_seats: numSeats
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

// GET - summary of all tickets for a given user
export async function getTickets(token) {
    const response = await axios.get(`${REACT_APP_BACKEND_URI}api/tickets`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

// [ADMIN] - GET - get all journeys
export async function getAllJourneys(token) {
    const response = await axios.get(`${REACT_APP_BACKEND_URI}api/all-journeys`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}


// GET - get all ticket details for a given journey and reference id
export async function getTicketDetails(token, journeyId, referenceId) {
    const response = await axios.get(`${REACT_APP_BACKEND_URI}api/ticket-details`, {
        params:{
            journey_id: journeyId,
            reference_id: referenceId
        },
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

// GET - check if user is in broadcast list for a given route
export async function checkBroadcast(token, date, routeId) {
    const response = await axios.get(`${REACT_APP_BACKEND_URI}api/check-broadcast`, {
        params: {
            date: date,
            routeId: routeId
        },
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

// POST - add user to broadcast list for a given journey
export async function addBroadcast(token, date, route) {
    const response = await axios.post(`${REACT_APP_BACKEND_URI}api/add-broadcast`, {
        date: date,
        route: route
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

// POST - login with token
export async function login(token) {
    try {
        const response = await axios.post(`${REACT_APP_BACKEND_URI}api/auth`, {
            token
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (response.status === 200 || response.status === 400 || response.status === 401) {
            // console.log(response.data.message);
        } else {
            console.log('Unexpected status code:', response.status);
        }

        return {
            data: response.data,
            statusCode: response.status
        };
    } catch (error) {
        console.log('Error:', error);
        throw error;
    }
}