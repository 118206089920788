import { useState, useEffect } from "react"
import { Card, CardContent, Typography, Box, Button, InputLabel, FormControl, Checkbox, FormControlLabel, Divider } from '@mui/material';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Input from '@mui/joy/Input';
import Snackbar from '@mui/joy/Snackbar';
import { CircularProgress } from '@mui/material';
import { getJourneysForStaff, getOneJourneyForStaff, getRouteNames, getTicketsForJourney, updateTicketStatus, batchUpdateTicketStatus, triggerIsBoarding, triggerIsDeparted, sendBroadcast } from '../networking/utils';
import Loading from './Loading';
import moment from 'moment';
import axios from 'axios';
import { motion } from "framer-motion";
import BookingListCard from "./BookingListCard";
import mrtcrowd from "../assets/mrtcrowd.png";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LinearProgress from '@mui/material/LinearProgress';
import DepartureIdComponent from "./DepartureIdComponent";
import PlateCard from "./PlateCard";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function StaffCheckin(props) {
    let query = useQuery();
    const jid = query.get("jid");
    const navigate = useNavigate();
    const [token, setToken] = useState(null)
    const [fetching, setFetching] = useState(false)
    const [parsedData, setParsedData] = useState([])
    const [fetchError, setFetchError] = useState(true)
    const [staffByDateInput, setStaffByDateInput] = useState({})
    const [searchQuery, setSearchQuery] = useState('')
    const [routeMapping, setRouteMapping] = useState({})
    const [tickets, setTickets] = useState([])
    const [loading, setLoading] = useState(false)
    const [ticketRefs, setTicketRefs] = useState([])
    const [searchFound, setSearchFound] = useState(false)

    useEffect(() => {
        if (searchQuery.length == 4) {
            let ticketRef = ticketRefs.find((ticketRef) => {
                return ticketRef.reference_id == searchQuery
            })
            if (ticketRef) {
                setSearchFound(true)
            } else {
                setSearchFound(false)
            }
        }
    }, [searchQuery])

    useEffect(() => {
        if (tickets && tickets.length > 0) {
            let ticketRefs = {}
            tickets.forEach((ticket) => {
                if (ticketRefs[ticket.reference_id]) {
                    ticketRefs[ticket.reference_id].push(ticket)
                } else {
                    ticketRefs[ticket.reference_id] = [ticket]
                }
            })
            console.log("ticket refs:", ticketRefs)

            let ticketRefKeys = Object.keys(ticketRefs)
            let newTicketRefs = []
            // for each ticket ref, create or update an object, if all tickets for a ref, update status to 2, if partial, update to 1, if none, update to 0
            ticketRefKeys.forEach((ticketRefKey) => {
                let ticketRef = ticketRefs[ticketRefKey]
                let totalTickets = ticketRef.length
                let checkedInTickets = 0
                ticketRef.forEach((ticket) => {
                    if (ticket.checked_in) {
                        checkedInTickets += 1
                    }
                })
                let status = 0
                if (checkedInTickets == totalTickets) {
                    status = 2
                } else if (checkedInTickets > 0) {
                    status = 1
                }
                let newTicketRef = {
                    reference_id: ticketRefKey,
                    tickets: ticketRef,
                    status: status
                }
                newTicketRefs.push(newTicketRef)
            })
            console.log("new ticket refs:", newTicketRefs)
            // sort by status 0, 1, 2
            newTicketRefs.sort((a, b) => {
                return a.status - b.status
            })
            setTicketRefs(newTicketRefs)
        }
    }, [tickets])

    const getTicketRefByQuery = () => {
        let ticketRef = ticketRefs.find((ticketRef) => {
            return ticketRef.reference_id == searchQuery
        }
        )
        return ticketRef
    }

    useEffect(() => {
        // dismiss keyboard on mobile if length of search query is 4
        if (searchQuery.length == 4) {
            document.activeElement.blur()
        }
    }, [searchQuery])

    const fetchTickets = async () => {
        try {
            // const routeResponse = await getRouteNames(props.token).then((response) => {
            //     let routeMapping = {}
            //     response.routes.forEach((route) => {
            //         routeMapping[route._id] = route
            //     })
            //     console.log("route mapping:", routeMapping)
            //     setRouteMapping(routeMapping)
            // }).catch((error) => {
            //     console.error('error fetching route names:', error)
            //     setFetchError(true)
            //     setFetching(false)
            // })
            const response = await getTicketsForJourney(props.token, jid).then((response) => {
                let tickets = response.tickets
                console.log("tickets:", tickets)
                setTickets(tickets)
                setFetchError(false)
                setFetching(false)
            }).catch((error) => {
                console.error('error fetching all tickets:', error)
                setFetchError(true)
                setFetching(false)
            })
        } catch (error) {
            console.error('error fetching all tickets:', error)
            setFetchError(true)
            setFetching(false)
        }
    }

    useEffect(() => {
        if (token && token != '') {
            setFetching(true)
            setFetchError(false)
            fetchTickets()
        }
    }, [token])

    useEffect(() => {
        props.setUseLocalStorage(true)
        if (props.currentTab >= 0) {
            props.setCurrentTab(props.tabMappingIndex)
        }
    }, [])


    useEffect(() => {
        if (props.token && props.token != '') {
            setToken(props.token)
        }
    }, [props.token])
    return (
        <div>

            {
                !token || token == '' || fetching &&
                <Loading />
            }

            {
                loading &&
                <div className="h-screen">
                <div className="h-4/6" style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <CircularProgress />
                </div>
                </div>
            }

            {
                !fetching && !fetchError && !tickets &&
                <Box className="flex flex-col gap-4 text-center mt-12 p-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* 
                    <Lottie
                        animationData={busLoadAnimation}
                        loop={true}
                        style={{
                            width: 200,
                            height: 200

                        }}
                    /> */}
                    <Typography variant="p" className="text-gray-400" fontWeight={800}>
                        Invalid Journey ID
                    </Typography>
                </Box>
            }


            {
                fetchError && !fetching &&
                <Box className="flex flex-col gap-4 px-4 py-6">

                    <div style={{
                        padding: '2em',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <h3 style={{
                            textAlign: 'center',
                            marginBottom: '1em',
                            color: '#333333'
                        }}>
                            Error fetching bookings
                        </h3>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setFetching(true)
                                setTimeout(() => {
                                    fetchTickets()
                                }, 1300)
                            }
                            }
                            style={{
                                maxWidth: '200px',
                            }}
                        >
                            Retry
                        </Button>
                    </div>
                </Box>
            }

            {
                !fetching && !fetchError && tickets && !loading &&
                <div className="w-screen p-4">
                    <div
                        className="cursor-pointer px-4 py-2 rounded-lg bg-gray-100 text-gray-500 text-xs text-center mb-3"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            navigate("/staff-journey?jid=" + jid)
                        }}>
                        <Typography variant="subtitle1" component="div" fontWeight={800} fontSize={11}>
                            « Ride Details
                        </Typography>
                    </div>
                    <Card className="w-full" raised style={{
                        borderRadius: '0.7em'
                    }}>
                        <CardContent sx={{
                            padding: "1em"
                        }}>
                            <div className="flex flex-col">
                                <Typography variant="h6" fontWeight={800} className="mb-4">
                                    Ticket Check-In
                                </Typography>
                                <div className="flex flex-row gap-4 justify-between">
                                    <div className="">
                                        <input type="number"
                                            value={searchQuery}
                                            placeholder="Search"
                                            className="w-full p-2 text-xl rounded-lg border-2 border-gray-300 mb-3"
                                            onChange={(e) => {
                                                setSearchQuery(e.target.value)
                                            }
                                            } />
                                    </div>
                                    <div>
                                        <motion.div
                                            whileHover={{ scale: 1.10 }}
                                            whileTap={{ scale: 0.9 }}
                                        >
                                            <div className="px-4 text-center text-white bg-slate-800 h-12 rounded-lg cursor-pointer items-center flex"
                                                onClick={() => {
                                                    // clear search query
                                                    setSearchQuery('')
                                                }}>
                                                <Typography variant="p" fontWeight={800} className="">
                                                    Clear
                                                </Typography>
                                            </div>
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {
                        // if search query is empty, show all ticket ref ids
                        searchQuery == '' &&
                        <div className="grid grid-cols-2 gap-4 mt-4">
                            {
                                ticketRefs.map((ticketRef, index) => {
                                    return (
                                        <div key={index} className="grid-item">
                                            <Card style={{
                                                // center everything
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '1em',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                textAlign: 'center',
                                                padding: '0.3em',
                                                borderRadius: '0.7em',
                                                backgroundColor: ticketRef.status == 2 ? '#A8E6CF' : ticketRef.status == 1 ? '#FFD3B6' : '#FFAAA5',
                                            }} raised
                                                onClick={() => {
                                                    setSearchQuery(ticketRef.reference_id)
                                                }}>
                                                <Typography variant="h6" fontWeight={800} className="mb-2">
                                                    {ticketRef.reference_id}
                                                </Typography>

                                                <Typography variant="p" fontWeight={600} style={{
                                                    fontSize: '0.8em'
                                                }} className="mb-2">
                                                    {ticketRef.tickets.length} pax
                                                </Typography>
                                            </Card>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }

                    {
                        searchQuery != '' && searchQuery.length != 4 &&
                        <div className="w-full p-4 flex text-center items-center text-gray-400 justify-around">
                            <Typography variant="p" fontWeight={400} className="mt-4">
                                Finish typing to search
                            </Typography>
                        </div>
                    }

                    {
                        searchQuery != '' && searchQuery.length == 4 && searchFound &&
                        <div className="flex flex-col">
                            <div className="w-full py-4">
                                <Card style={{
                                    // center everything
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '1em',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    padding: '0.8em',
                                    borderRadius: '0.7em',
                                    backgroundColor: getTicketRefByQuery().status == 2 ? '#A8E6CF' : getTicketRefByQuery().status == 1 ? '#FFD3B6' : '#FFAAA5',
                                }} raised
                                    onClick={() => {
                                        setLoading(true)
                                        let new_state = getTicketRefByQuery().status < 2 ? true : false
                                        batchUpdateTicketStatus(props.token, searchQuery, new_state).then((response) => {
                                            console.log("batch ticket status updated:", response)
                                            fetchTickets()
                                            setLoading(false)
                                        }).catch((error) => {
                                            console.error('error updating ticket status:', error)
                                            setLoading(false)
                                        })
                                    }}>
                                    <Typography variant="p" fontWeight={600} className="mb-2">
                                        {getTicketRefByQuery().status < 2 ? 'Check-In All' : 'Undo Check-In'}
                                    </Typography>
                                </Card>
                            </div>
                        <div className="grid grid-cols-1 gap-4 mt-4">
                            {
                                ticketRefs.map((ticketRef, index) => {
                                    if (ticketRef.reference_id != searchQuery) {
                                        return
                                    }
                                    return (
                                        ticketRef.tickets.map((ticket, index) => {
                                            return (
                                                <div key={index} className="grid-item">
                                                    <Card style={{
                                                        // center everything
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        gap: '1em',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        textAlign: 'center',
                                                        padding: '0.3em',
                                                        borderRadius: '0.7em',
                                                        backgroundColor: ticket.status != "active" ? "#000" : ticket.checked_in ? '#A8E6CF' : '#FFAAA5',
                                                    }} raised
                                                        onClick={() => {
                                                            let new_state = ticket.checked_in ? false : true
                                                            // console.log("ticket clicked:", ticket)
                                                            // console.log("sending update ticket status request:", ticket._id, new_state, props.token)
                                                            updateTicketStatus(props.token, ticket._id, new_state).then((response) => {
                                                                console.log("ticket status updated:", response)
                                                                fetchTickets()
                                                            }).catch((error) => {
                                                                console.error('error updating ticket status:', error)
                                                            })
                                                        }}>
                                                        <Typography variant="h3" fontWeight={800} className="mb-2">
                                                            {ticket.security_code}
                                                        </Typography>
                                                    </Card>
                                                </div>
                                            )
                                        })

                                    )
                                })
                            }
                        </div>
                        </div>
                    }

                    {
                        searchQuery != '' && searchQuery.length == 4 && !searchFound &&
                        <div className="w-full p-4 flex text-center items-center text-gray-400 justify-around">
                            <Typography variant="p" fontWeight={400} className="mt-4">
                                No matching reference ID found
                            </Typography>
                        </div>
                    }
                </div>
            }
        </div>
    )
}


function BoardingPill() {
    return (

        <div>
            <div className="bg-cyan-500 text-cyan-100 rounded-full px-2 py-0.5 text-center flex justify-around mr-1">

                <Typography variant="p" fontWeight={700} style={{
                    fontSize: '0.65em'
                }}>
                    Boarding
                </Typography>
            </div>
        </div>
    )
}


function DepartedPill() {
    return (

        <div>
            <div className="bg-green-500 text-green-100 rounded-full px-2 py-0.5 text-center flex justify-around mr-1">
                <Typography variant="p" fontWeight={700} style={{
                    fontSize: '0.65em',
                }}>
                    Departed
                </Typography>
            </div>
        </div>
    )
}


export default StaffCheckin
