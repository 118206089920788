import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Home from './components/Home';
// import About from './components/About';
import Login from './components/Login';
import Book from './components/MyBookings';
import { login } from './networking/utils';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Divider } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';
// import App.css
import './App.css';
import Success from './components/Success';
import Cancelled from './components/Cancelled';
import MyBookings from './components/MyBookings';
import BookingDetails from './components/BookingDetails';
import logo from './components/hitchvibe_logo.png';
import { useCookies } from 'react-cookie';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import StarIcon from '@mui/icons-material/Star';
import AdminPage from './components/AdminPage';
import StaffHomePage from './components/StaffHomePage';
import StaffDetailsPage from './components/StaffDetailsPage';
import StaffCheckin from './components/StaffCheckin';
import FAQ from './components/FAQ';


// import localstorage

const App = () => {
  const [tabMapping, setTabMapping] = useState({
    0: '/',
    1: '/bookings',
    // 2: '/profile',
    // 3: '/admin',
    // 4: '/staff'
    2: '/faq'
  });
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const navigate = useNavigate();
  const [useLocalStorage, setUseLocalStorage] = useState(false);
  const [roles, setRoles] = useState([]);
  const [authed, setAuthed] = useState(false);
  const [token, setToken] = useState(null);
  const [currentTab, setCurrentTab] = useState(0); // -1 - hide, 0 - home, 1 - bookings, 2 - profile
  // validate token

  useEffect(() => {
    if (roles.length > 0) {
      console.log('roles:', roles)
    }
    // adjust tabMapping based on roles
    if (roles.includes('admin')) {
      console.log('changed tab mapping for admin')
      setTabMapping({
        0: '/',
        1: '/bookings',
        2: '/admin',
        3: '/staff',
        4: '/faq'
      });
    } else if (roles.includes('staff')) {
      setTabMapping({
        0: '/',
        1: '/bookings',
        3: '/staff',
        4: '/faq'
      });
    } else {
      setTabMapping({
        0: '/',
        1: '/bookings',
        4: '/faq'
      });
    }
  }, [roles]);
  const validate_token = async (token) => {
    // console.log('validating token on app.js', token)
    login(token)
      .then(({ data, statusCode }) => {
        // console.log("auth response in login:", statusCode)
        if (statusCode === 200) {
          // console.log("auth validated")
          // set token to localstorage
          // console.log("login data:", data)
          setRoles(data.roles);
          setToken(token);
        } else {
          // console.log('auth failed')
          // redirect to /login/:token
          // console.log("navigating to", `/login/${token}`)
          navigate(`/login/${token}`);
        }
      })
      .catch(error => {
        console.error('error during login:', error);
        // redirect to /login/:token
        navigate(`/login/${token}`);
      });
  }

  useEffect(() => {
    if (useLocalStorage && !authed) {
      const lsToken = cookies.token
      // console.log("cookies:", cookies)
      // console.log("setting token from localstorage", lsToken)
      if (lsToken) {
        setToken(lsToken);
      } else {
        // no token, redirect to login
        navigate(`/login/invalid`)
      }
    }
  }, [useLocalStorage]);

  useEffect(() => {
    if (token && !authed) {
      // console.log('validating token:', token)
      validate_token(token);
      setAuthed(true);
      setCookie('token', token, {
        path: '/',
        maxAge: 3600 * 24 * 365, // 1 year
      });
    } else {
      // console.log('no token to validate')
    }
  }, [token]);

  const setValidatedToken = (token) => {
    setAuthed(true);
    setCookie('token', token, {
      path: '/',
      maxAge: 3600 * 24 * 365, // 1 year
    });
    // console.log('setting token:', token);
    setToken(token);
  }
  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <img src={logo} alt="HitchVibe" className='mt-2 mb-2 ml-4' style={{ height: 'auto', width: '100%', maxWidth: '120px' }} />
      <Scrollbars style={{ flex: 1 }} autoHide>
        <Routes>
          <Route exact path="/login/:token" element={
            <Login
              useLocalStorage={useLocalStorage}
              setUseLocalStorage={setUseLocalStorage}
              currentTab={currentTab}
              setValidatedToken={setValidatedToken}
              setCurrentTab={setCurrentTab}
              setRoles={setRoles}
            />
          } />
          <Route exact path="/bookings" element={
            <MyBookings
              useLocalStorage={useLocalStorage}
              setUseLocalStorage={setUseLocalStorage}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              token={token}
            />
          }
          />
          <Route exact path="/booking-details" element={
            <BookingDetails
              useLocalStorage={useLocalStorage}
              setUseLocalStorage={setUseLocalStorage}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              token={token}
            />
          }
          />
          <Route exact path="/success" element={
            <Success />
          } />
          <Route exact path="/cancel" element={
            <Cancelled />
          } />
          <Route exact path="/" element={
            <Home
              useLocalStorage={useLocalStorage}
              setUseLocalStorage={setUseLocalStorage}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              token={token}
            />
          }
          />

          <Route exact path="/staff-journey" element={
            <StaffDetailsPage
              useLocalStorage={useLocalStorage}
              setUseLocalStorage={setUseLocalStorage}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              token={token}
              tabMappingIndex={3}
            />
          } />


          <Route exact path="/staff-checkin" element={
            <StaffCheckin
              useLocalStorage={useLocalStorage}
              setUseLocalStorage={setUseLocalStorage}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              token={token}
              tabMappingIndex={3}
            />
          } />

          <Route exact path="/staff" element={
            <StaffHomePage
              useLocalStorage={useLocalStorage}
              setUseLocalStorage={setUseLocalStorage}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              token={token}
              tabMappingIndex={3}
            />
          } />

          <Route exact path="/admin" element={
            <AdminPage
              useLocalStorage={useLocalStorage}
              setUseLocalStorage={setUseLocalStorage}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              tabMappingIndex={2}
              token={token}
            />
          } />

          <Route exact path="/faq" element={
            <FAQ
              useLocalStorage={useLocalStorage}
              setUseLocalStorage={setUseLocalStorage}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              tabMappingIndex={( roles && (roles.includes('admin') || roles.includes('staff')) ) ? 4 : 2}
              token={token}
            />
          } />

        </Routes>
      </Scrollbars>
      <SimpleBottomNavigation
        tabMapping={tabMapping}
        currentTab={currentTab}
        roles={roles}
        setCurrentTab={setCurrentTab}
      />
    </div>
  );
};

export default App;

function SimpleBottomNavigation(props) {
  const navigate = useNavigate();
  let roles = props.roles;
  let tabMapping = props.tabMapping;

  return (
    <Box
      sx={{ width: '100%' }}
      style={{
        // hide if props.currentTab is -1
        display: props.currentTab === -1 ? 'none' : 'block'
      }} >
      <Divider />
      <BottomNavigation
        showLabels
        value={props.currentTab}
        onChange={(event, newValue) => {
          props.setCurrentTab(newValue);
          navigate(tabMapping[newValue]);
        }}
      >
        <BottomNavigationAction label="Book a HitchVibe"
          icon={<DirectionsBusIcon />}
        />
        <BottomNavigationAction label="My Bookings"
          icon={<ConfirmationNumberIcon />}
        />
        {
          roles && roles.includes('admin') &&
          <BottomNavigationAction label="Admin"
            icon={<ImportantDevicesIcon />}
          />
        }

        {roles && roles.includes('staff') &&
          <BottomNavigationAction label="Staff"
            icon={<StarIcon />}
          />
        }


        <BottomNavigationAction label="FAQ"
          icon={<HelpOutlineIcon/>}
        />

      </BottomNavigation>
    </Box>
  );
}